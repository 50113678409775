/**
 * Mocking client-server processing
 */
// import {firestore} from "./firebase";
import axios from "axios";
import {REST_URL} from "./configs";

// import _products from './data.json'

const TIMEOUT = 10000
let items = []
let tr_items = []
let page_no = 1

export default {
    getProducts: (cb, q, limit = 3, skip = 0) => {
        // Firebase Provider
        /*firestore.collection("products")
            //.where("collection", '==', 'top')
            .limit(20)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.data());
                    items.push(doc.data())
                });
                //cb(items)
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });*/
        // Mongo DB provider
        let token;
        if (token) {
            // Cancel the previous request before making a new request
            token.cancel()
        }
        token = axios.CancelToken.source()
        let url = `${REST_URL}products/${limit}&${skip}/`
        const ax_config = {
            method: 'post',
            url: url,
            cancelToken: token.token
        }
        if (q !== undefined)
            ax_config["data"] = q

        axios(ax_config)
            .then((res) => {
                setTimeout(() => {
                    if (skip === 0) {
                        document.querySelector(".loader-wrapper").style = "display: none";
                    }
                    cb(res.data)
                }, 500)
            })
            .catch(err => {
                cb(err)
            })
        /*if (old.length !== 0) {
            let url = REST_URL/!*"http://localhost:3000/"*!/ + "products/4&" + (4 * (page_no - 1)) + "/"
            if (q !== undefined)
                url += q
            axios
                .get(
                    url,
                    {cancelToken: token.token}
                )
                //.get(REST_URL/!*"http://localhost:3000/"*!/ + "products/4&0")
                .then((res) => {
                    if(q !== undefined)
                    {
                        cb(res.data)
                    }
                    else
                    {
                        page_no = page_no + 1
                        items = [...old, ...res.data]
                        cb(items)
                    }

                })
                .catch((error) => {
                    cb(error)
                })
        } else {
            let url = REST_URL/!*"http://localhost:3000/"*!/ + "products/4&0/";
            if (q !== undefined)
                url += q

            axios
                .get(
                    url,
                    {cancelToken: token.token}
                )
                .then((res) => {
                    if(q !== undefined)
                    {
                        cb(res.data)
                    }
                    else
                    {
                        page_no = page_no + 1
                        items = res.data
                        cb(items)
                    }

                    setTimeout(() => {
                        document.querySelector(".loader-wrapper").style = "display: none";
                    }, 1000)
                })
                .catch(err => {
                    cb(err)
                })
        }*/
    },
    getTrendingProducts: (cb) => {
        // document.querySelector(".loader-wrapper").style = "display: block"
        axios
            .get(REST_URL/*"http://localhost:3000/"*/ + "products/trending/8&0")
            .then((res) => {
                tr_items = res.data
                cb(tr_items)
                // document.querySelector(".loader-wrapper").style = "display: none"
            })
            .catch(err => {
                cb(err)
            })
    },
    getProductReviews: (cb, product_id, per_page, page_no) => {
        axios
            .get(
                REST_URL + "reviews/" + product_id + "&" + per_page + "&" + page_no,
            )
            .then((res) => {
                cb(res)
            })
    },
    getShippingCharges: (cb, pin_code, weight, token) => {
        const config = {
            headers: {
                authtoken: token
            }
        }

        axios
            .post(
                REST_URL + "shipping/checkServiceability/",
                {
                    pincode: pin_code,
                    weight: weight
                },
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}
