import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslate} from 'react-redux-multilingual'
import {connect} from "react-redux";
import {logoutUser} from "../../../../actions";

class TopBar extends Component {

    handleLogoutClick = () => {
        if (this.props.user.auth_token)
        {
            this.props.logoutUser()
        }
    }

    render() {
        const {translate} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>Welcome to Rajratna Natural Products Store &nbsp;&nbsp;&nbsp;<i
                                        className="fa fa-phone" aria-hidden="true"/>{translate('call_us')}:
                                        +918999148052
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="mobile-wishlist compare-mobile">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/compare`}>
                                        <i className="fa fa-random"
                                           aria-hidden="true"/>
                                        {translate('compare')}
                                    </Link>
                                </li>
                                <li className="mobile-wishlist">
                                    <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                                        <i
                                            className="fa fa-heart" aria-hidden="true"/>
                                            {translate('wishlist')}
                                    </Link>
                                </li>
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user"
                                       aria-hidden="true"/>
                                    {!(this.props.user.name === undefined || this.props.user.name === '') ? this.props.user.name : translate('my_account')}
                                    <ul className="onhover-show-div">
                                        <li>
                                            {
                                                !(this.props.user.name === undefined || this.props.user.name === '') ?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/pages/dashboard`}
                                                        data-lng="en">
                                                        My Profile
                                                    </Link>
                                                    :
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/pages/login`}
                                                        data-lng="en">
                                                        Login
                                                    </Link>
                                            }
                                        </li>
                                        <li onClick={this.handleLogoutClick}>
                                            {
                                                !(this.props.user.name === undefined || this.props.user.name === '') ?
                                                    "Logout"
                                                    :
                                                    <Link to={`${process.env.PUBLIC_URL}/pages/register`}
                                                          data-lng="en">Register</Link>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userInfo
})
export default connect(
    mapStateToProps,
    {logoutUser}
)(withTranslate(TopBar));