import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";
import {connect} from 'react-redux'
import {fetchUserToken} from "../../actions";
import SimpleReactValidator from "simple-react-validator";

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
        }

        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        const obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    loginBtnHandler = () => {
        if (this.validator.allValid()) {
            this.props.fetchUserToken(this.state.email, this.state.password)
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userInfo.auth_token !== this.props.userInfo.auth_token) {
            window.open("/", "_self")
        }
    }

    render() {
        if (this.props.userInfo.auth_token !== '') {
            window.open("/", "_self")
        }

        return (
            <div>
                <Breadcrumb title={'Login'}/>


                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                onChange={this.setStateFromInput}
                                                value={this.state.email}
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email"/>
                                            {this.validator.message('email', this.state.email, 'required|email')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input
                                                onChange={this.setStateFromInput}
                                                value={this.state.password}
                                                type="password"
                                                className="form-control"
                                                id="review"
                                                name="password"
                                                placeholder="Enter your password"/>
                                            {this.validator.message('password', this.state.password, 'required|min:6|alpha_num_dash')}
                                        </div>
                                        <div onClick={() => this.loginBtnHandler()} className="btn btn-solid">Login
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create A Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                    <a href="#" className="btn btn-solid">Create an Account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
})

export default connect(
    mapStateToProps,
    {fetchUserToken}
)(Login)