import axios from "axios";
import {REST_URL} from "./configs";

export default {
    regUserFromREST: (cb, name, email, phone, password) => {
        axios
            .post(
                REST_URL + "auth/registerUser",
                {
                    name: name,
                    email: email,
                    phoneNumber: phone,
                    password: password
                })
            .then((res) => {
                cb(res)
            })
            .catch((reason) => {
                cb(reason.response)
            })
    },
    getTokenFromREST: (cb, email, password) => {
        axios
            .post(
                REST_URL + "auth/loginUser",
                {
                    email: email,
                    password: password
                })
            .then((res) => {
                cb(res)
            })
            .catch((reason) => {
                cb(reason.response)
            })
    },
    verifyTokenFromREST: (cb, token) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .post(
                REST_URL + "auth/verifyToken",
                {},
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    getUserAddresses: (cb, token) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .get(
                REST_URL/*"http://localhost:3000/"*/ + "address/0&0",
                config
            )
            .then((res) => {
                cb(res)
            })
    },
    saveUserAddress: (cb, token, address) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .post(
                REST_URL/*"http://localhost:3000/"*/ + "address/",
                address,
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    createUserOrder: (cb, token, order_detail) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .post(
                REST_URL/*"http://localhost:3000/"*/ + "payments/createInvoice/",
                order_detail,
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    paidUserOrder: (cb, token, invoice_id) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .post(
                REST_URL/*"http://localhost:3000/"*/ + "payments/getRzpOrderId/",
                {
                    invoice_id: invoice_id
                },
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    fetchUserOrders: (cb, token, limit, skip) => {
        const config = {
            headers: {
                authtoken: token
            },
        }
        axios
            .get(
                REST_URL/*"http://localhost:3000/"*/ + "orders/" + limit + "&" + skip,
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    },
    submitReview: (cb, token, review) => {
        const config = {
            headers: {
                authtoken: token
            }
        }
        axios
            .post(
                REST_URL/*"http://localhost:3000/"*/ + "reviews/",
                review,
                config
            )
            .then((res) => {
                cb(res)
            })
            .catch(err => {
                cb(err.response)
            })
    }
}