import PostLoader from "./PostLoader";
import React from "react";

export const ProductListLoader = () => {

    return <div className="row mx-0 margin-default my-2">
        <div className="col-xl-4 col-lg-6 col-8">
            <PostLoader/>
        </div>
        <div className="col-xl-4 col-lg-6 col-8">
            <PostLoader/>
        </div>
        <div className="col-xl-4 col-lg-6 col-8">
            <PostLoader/>
        </div>
    </div>
}