import React, {Component} from 'react';


class orderSuccess extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const {payment, items, symbol, orderTotal, address, shipping_charge, phone} = this.props.location.state ?
            this.props.location.state
            :
            {
                payment: undefined,
                items: undefined,
                symbol: undefined,
                orderTotal: undefined,
                address: undefined,
            }
        ;
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        const current = new Date();
        const next5days = new Date(Date.now() + 5 * 86400000);
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        let deliveryDate = next5days.toLocaleDateString("en-US", options).toString()

        return (
            (payment && items && symbol && orderTotal && address) ?
                <div>
                    <section className="section-b-space light-layout">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="success-text">
                                        <i className="fa fa-check-circle" aria-hidden="true"/>
                                        <h2>thank you</h2>
                                        <p>Payment Is Has Been Received Order Placed Successfully</p>
                                        <p>Transaction ID: {(payment.paymentID) ? payment.paymentID : payment.id}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="product-order">
                                        <h3>your order details</h3>
                                        {items.map((item, index) => {
                                            return <div className="row product-order-detail" key={index}>
                                                <div className="col-3">
                                                    <img src={item.variants ?
                                                        item.variants[0].images
                                                        : item.pictures[0]} alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>product name</h4>
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>quantity</h4>
                                                        <h5>{item.qty}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>price</h4>
                                                        <h5>{symbol}{item.sum}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <div className="total-sec">
                                            <ul>
                                                <li>subtotal <span>{symbol}{orderTotal}</span></li>
                                                <li>shipping <span>{symbol}{shipping_charge}</span></li>
                                            </ul>
                                        </div>
                                        <div className="final-total">
                                            <h3>total <span>{symbol}{(orderTotal + shipping_charge)}</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row order-success-sec">
                                        <div className="col-sm-6">
                                            <h4>summery</h4>
                                            <ul className="order-detail">
                                                {(payment.paymentID) ?
                                                    <div>
                                                        <li>payment ID: {payment.paymentID}</li>
                                                    </div>
                                                    :
                                                    <li>Order ID: {payment.id}</li>}

                                                <li>Order Date: {CheckDate}</li>
                                                <li>Order Total: {symbol}{orderTotal}</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <h4>shipping address</h4>
                                            <ul className="order-detail">
                                                <li>{address.line1}</li>
                                                <li>{address.line2}, {address.city}</li>
                                                <li>{address.state}, {address.country}, {address.zipcode}</li>
                                                <li>Contact No. {phone}</li>
                                            </ul>
                                        </div>

                                        <div className="col-sm-12 payment-mode">
                                            <h4>payment method</h4>
                                            <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net
                                                banking acceptance subject to device availability.</p>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="delivery-sec">
                                                <h3>expected date of delivery</h3>
                                                <h2>{deliveryDate}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                :
                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h1>404</h1>
                                    <h2>page not found</h2>
                                    <a href="index.html" className="btn btn-solid">back to home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}

export default orderSuccess
