import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export default function SectionTypeSelectTextField(props) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    fullWidth={true}
                    select
                    label="Select"
                    value={props.section_type}
                    onChange={(e) => {
                        props.setSection(e.target.value)
                    }}
                    //helperText="Please select your address type"
                    variant="outlined"
                >
                    {props.sections.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </form>
    );
}