import React from 'react';
import Loader from "react-loader-spinner";

const ImageZoom = (props) => {
    const [loading, setLoading] = React.useState(true)
    const {image} = props;

    return (
        <React.Fragment>
            {
                loading &&
                <Loader
                    style={{
                        width: "10%",
                        margin: "0 auto"
                    }}
                    // className={"pt-1"}
                    type="Oval"
                    color="#f0b54d"
                    height={"30vh"}
                    width={50}
                    // timeout={3000} //3 secs
                />
            }
            <img
                onLoad={() => setTimeout(() => setLoading(false), 500)}
                style={{
                    display: loading ? "none" : "block"
                }}
                src={`${image}`}
                className="img-fluid image_zoom_cls-0"
                alt=""/>
        </React.Fragment>
    );
}

export default ImageZoom