import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CheckCircle, DeleteForever, Edit} from "@material-ui/icons";

const ChkAddressGridItem = (props) => {
    const useStyles = makeStyles((theme) => ({
        paperItem: {
            padding: theme.spacing(1),
            //width: 'max-content'
            borderLeft: props.selected ? "0.3em solid blue" : null,
            borderRight: props.selected ? "0.2em solid blue" : null,
            borderTop: props.selected ? "0.2em solid blue" : null,
            borderBottom: props.selected ? "0.2em solid blue" : null,
            position: 'relative',
        },
        gridItem: {},
        actionsDiv: {
            //width: 'max-content',
            textAlign: "right"
        },
        checkBoxIcon: {
            color: 'green',
            position: 'absolute',
            top: '5%',
            right: '5%'
        }
    }))
    const classes = useStyles()

    return (
        <Grid
            className={classes.gridItem}
            xs
            item>
            <Paper
                onClick={props.clicked}
                variant={"outlined"}
                className={classes.paperItem}>
                {
                    props.selected && <CheckCircle className={classes.checkBoxIcon} />
                }
                <h6>Saved Address</h6>
                <Typography variant={"inherit"}>
                    {props.line1},<br/>
                    {props.line2},<br/>
                    {props.city},&nbsp;
                    {props.state},&nbsp;
                    {props.country},&nbsp;
                    {props.zipcode}<br/>
                    (This Address is For {props.address_type})
                </Typography>
            </Paper>
        </Grid>
    )
}

export default ChkAddressGridItem