import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const currencies = [
    {
        value: 'shipping',
        label: 'Shipping',
    },
    {
        value: 'billing',
        label: 'Billing',
    },
];

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export default function AddressTypeSelectTextField(props) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    fullWidth={true}
                    select
                    value={props.address_type}
                    onChange={props.setAddressType}
                    helperText="Please select your address type"
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </form>
    );
}