import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {getNewProducts} from "../../services";
import Rating from "./Rating";
import Loader from "react-loader-spinner";
import ContentLoader from "react-content-loader";

class NewProduct extends Component {

    componentDidMount() {
        this.props.getNewProducts()
    }

    render() {
        const arrays = []
        const {items, symbol} = this.props;
        // console.log(items)
        while (items.length > 0) {
            arrays.push(items.splice(0, 3))
        }

        const CompImage = (props) => {
            const {product} = props
            const [loading, setLoading] = React.useState(true)

            return (
                <React.Fragment>
                    {
                        loading &&
                        <div className="py-1">
                            <ContentLoader className="skeleton-new-product-svg" speed={10}>
                                <rect x="0" y="0" rx="5" ry="5" width="170" height="140" />
                                <rect x="180" y="17" rx="4" ry="4" width="80" height="13" />
                                <rect x="180" y="40" rx="3" ry="3" width="70" height="10" />
                                <rect x="180" y="57" rx="3" ry="3" width="60" height="10" />
                            </ContentLoader>
                        </div>
                    }
                    <Link
                        style={{display: loading ? "none" : "block"}}
                        to={`${process.env.PUBLIC_URL}/no-sidebar/product/${product._id}`}>
                        <img
                            // style={{padding: "0 5px 0 0px"}}
                            onLoad={() => setTimeout(() => setLoading(false),1000)}
                            src={`${product.pictures[0]}?q=100&w=1000&h=736`}
                            alt=""
                            className={"img-fluid"}
                        />
                    </Link>
                    <div
                        style={{display: loading ? "none" : "block"}}
                        className="media-body align-self-center">
                        <Rating rating={product.rating}/>
                        <Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/${product._id}`}>
                            <h6>{product.name}</h6></Link>
                        <h4>{symbol}{product.discount !== 0 ? (product.price - (product.price * product.discount / 100)) : product.price}
                            <del><span className="money">{symbol}{product.price}</span></del>
                        </h4>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <div className="theme-card">
                <h5 className="title-border">new product</h5>
                <Slider className="offer-slider slide-1">
                    {arrays.map((products, index) =>
                        <div key={index}>
                            {products.map((product, i) =>
                                <div className="media" key={i}>
                                    <CompImage product={product} />
                                </div>
                            )}
                        </div>
                    )}
                </Slider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: state.data.new_products,
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {
    getNewProducts
})(NewProduct);
