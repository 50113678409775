import React from "react";
import Loader from "react-loader-spinner";

const CompImage = (props) => {
    const {src, classnames} = props
    const [loading, setLoading] = React.useState(true)

    return (
        <React.Fragment>
            {
                loading &&
                <Loader
                    style={{
                        width: "10%",
                        margin: "0 auto"
                    }}
                    className={"pt-1"}
                    type="Rings"
                    color="#f0b54d"
                    height={"25vh"}
                    width={30}
                />
            }
            <img
                style={{
                    display: loading ? "none" : "block"
                }}
                src={src}
                className={classnames}
                alt=""
                onLoad={() => setTimeout(() => setLoading(false), 500)}
            />
        </React.Fragment>
    )
}

export default CompImage