import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import auth from "../api/auth";

// User
export const receiveUserToken = (token) => ({
    type: types.FETCH_USER_TOKEN,
    token
})

export const receiveUserProfile = (user) => dispatch => {
    dispatch({
        type: types.FETCH_USER_PROFILE,
        user
    })
}

export const registerUser = (name, email, phone, password) => dispatch => {
    auth.regUserFromREST(res => {
        if(res.status === 200) {
            dispatch(receiveUserToken(res.headers["authtoken"]))
            dispatch(receiveUserProfile(res.data))
            toast.success("Registration Successful!")
        }
        else
            toast.warn(res.data)
    }, name, email, phone, password)
}

export const receiveUserAddresses = (addresses) => dispatch => {
    dispatch({
        type: types.USER_ADDRESSES,
        addresses
    })
}

export const selectUserAddresses = (index) => dispatch => {
    dispatch({
        type: types.SELECT_USER_ADDRESS,
        index
    })
}

export const deselectUserAddresses = (index) => dispatch => {
    dispatch({
        type: types.DESELECT_USER_ADDRESS,
        index
    })
}

export const logoutUser = () => dispatch => {
    dispatch({
        type: types.USER_LOGOUT
    })
    toast.warn("You have been logged out!")
}

export const fetchUserToken = (email, password) => dispatch => {
    auth.getTokenFromREST(res => {
        if (res.status === 200) {
            toast.success("Login Successful!");
            dispatch(receiveUserToken(res.data.authtoken))
            dispatch(receiveUserProfile(res.data))
        } else
            toast.error(res.data)
    }, email, password)
}

// Product
export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});


export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

let hasMore = true
export const getAllProducts = (old) => dispatch => {
    dispatch(fetchProductsBegin());
    if (old) {
        // document.querySelector(".loader-wrapper").style = "display: none";
        shop.getProducts(products => {
            if (products.length === 0) {
                hasMore = false
            } else
                dispatch(receiveProducts(old.concat(products)))
            return products
        }, undefined, 3, old.length)
    } else {
        shop.getProducts(products => {
            if(Array.isArray(products))
            {
                dispatch(receiveProducts(products))
            }
            else
                alert(products.message)
        }, undefined)
    }
    return hasMore
}

export const receiveProduct = product => ({
    type: types.RECEIVE_PRODUCT,
    product
})

export const addProductFromREST = (product) => dispatch => {
    dispatch(receiveProduct(product))
}

export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})

export const receiveTrendingProducts = tr_products => ({
    type: types.FETCH_TRENDING_PRODUCT,
    tr_products
})

export const receiveNewProducts = products => ({
    type: types.FETCH_NEW_PRODUCTS,
    products
})

export const fetchTrendingProducts = () => dispatch => {
    shop.getTrendingProducts(tr_products => {
        if(Array.isArray(tr_products))
        {
            dispatch(receiveTrendingProducts(tr_products))
            return tr_products
        }
        else
            alert(tr_products.message)
    });
}

export const clearProducts = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_ALL_PRODUCTS
    })
}

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty))

}
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product_id => (dispatch) => {
    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id
    })
};

export const clearCart = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_CART
    })
}

export const incrementQty = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty))
}

export const updateQty = (product, qty) => (dispatch) => {
    toast.info("Item Quantity Updated")
    dispatch({
        type: types.UPDATE_QTY,
        product: product,
        qty: qty
    })
}

export const decrementQty = productId => (dispatch) => {
    toast.warn("Item Decrement Qty to Cart");

    dispatch({
        type: types.DECREMENT_QTY,
        productId
    })
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterCategory = (category) => ({
    type: types.FILTER_CATEGORY,
    category
})

export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

