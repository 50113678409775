// Get Unique Brands from Json Data

import shop from "../api/shop";
import {receiveNewProducts, receiveProducts,} from "../actions";

export const getCategories = () => {
    return [
        "soap",
        "facial bomb"
    ];
}

// Get Unique Brands from Json Data
export const getBrands = (products) => {
    const uniqueBrands = [];
    products.map((product, index) => {
        if (product.tags) {
            product.tags.map((tag) => {
                if (uniqueBrands.indexOf(tag) === -1) {
                    uniqueBrands.push(tag);
                }
            })
        }
    })
    //console.log(uniqueBrands)
    return uniqueBrands;
}

// Get Unique Colors from Json Data
export const getColors = (products) => {
    const uniqueColors = [];
    products.map((product, index) => {
        if (product.colors) {
            product.colors.map((color) => {
                if (uniqueColors.indexOf(color) === -1) {
                    uniqueColors.push(color);
                }
            })
        }
    })
    //console.log(uniqueBrands)
    return uniqueColors;
}

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = () => {
    let min = 30, max = 2000;

    /*    products.map((product, index) => {
            let v = product.price;
            min = (v < min) ? v : min;
            max = (v > max) ? v : max;
        })*/

    return {'min': min, 'max': max};
}

export const getVisibleProducts = (products, limit, {brand, category, value, sortBy}, cb) => dispatch => {
    const promise = new Promise(resolve => {
        const data = {}
        const ftr = []
        if (sortBy === 'HighToLow') {
            data["sr_d"] = "price"
        } else if (sortBy === 'LowToHigh') {
            data["sr_a"] = "price"
        } else if (sortBy === 'Newest') {
            data["sr_d"] = "new"
        } else if (sortBy === 'AscOrder') {
            data["sr_a"] = "name"
        } else if (sortBy === 'DescOrder') {
            data["sr_d"] = "name"
        }

        if (category.length !== 0) {
            ftr.push({
                name: "category",
                value: category
            })
        }

        ftr.push({
            name: "price",
            min_val: value.min,
            max_val: value.max
        })

        data["fb"] = ftr

        // console.log(data)

        shop.getProducts(cb => {
            // console.log("getVisibleProducts", cb)
            resolve(cb)
        }, data, limit, products.length)
    })
    promise.then(prd => {
        dispatch(receiveProducts(products.concat(prd)))
        if (cb !== undefined)
            cb(prd)

    })

    /*return data.products.filter((item, index) => {
        const startPriceMatch = typeof value.min !== 'number' || value.min <= item.price;
        const endPriceMatch = typeof value.max !== 'number' || item.price <= value.max;
        return ((brand.length !== 0 && category.length !== 0) ?
                (item.tags.some(br => brand.includes(br)) && category.some(cat => cat === item.category) && startPriceMatch && endPriceMatch)
                :
                (brand.length !== 0) ?
                    (item.tags.some(tag => brand.includes(tag)) && startPriceMatch && endPriceMatch)
                    :
                    (category.some(cat => cat === item.category) && startPriceMatch && endPriceMatch)
        )
    }).sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            return product2.price < product1.price ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            return product2.price > product1.price ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else {
            return product2._id > product1._id ? -1 : 1;
        }
    })*/
}

export const getCartTotal = cartItems => {
    let total = 0;
    for (let i = 0; i < cartItems.length; i++) {
        cartItems[i].discount ?
            total += parseInt(cartItems[i].qty, 10) * parseInt((cartItems[i].price - (cartItems[i].price * cartItems[i].discount / 100)), 10)
            :
            total += parseInt(cartItems[i].qty, 10) * parseInt((cartItems[i].price), 10)
    }
    return total;
}

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.tags.includes(tag);
    })
    return items.slice(0, 8)
}

// Get Trending Collection
export const getTrendingCollection = (products) => {
    return products
}

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0, 5)
}

// Get TOP Collection
export const getTopCollection = products => {
    const items = products.filter(product => {
        return product.rating > 4;
    })
    return items.slice(0, 8)
}

// Get New Products
export const getNewProducts = () => dispatch => {
    const promise = new Promise(resolve => {
        shop.getProducts(cb => {
            resolve(cb)
        }, {
            fb: [{
                "new": true
            }]
        }, 6, 0)
    })
    promise.then((prd) => {
        dispatch(receiveNewProducts(prd))
    })
}

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })

    return items.slice(0, 4)

}

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter(product => {
        return product.sale === true && product.category === type;
    })

    return items.slice(0, 8)
}

// Get Best Seller
export const getBestSeller = products => {
    const items = products.filter(product => {
        return product.sale === true;
    })

    return items.slice(0, 8)
}

// Get Mens Wear
export const getMensWear = products => {
    const items = products.filter(product => {
        return product.category === 'men';
    })

    return items.slice(0, 8)
}

// Get Womens Wear
export const getWomensWear = products => {
    const items = products.filter(product => {
        return product.category === 'women';
    })

    return items.slice(0, 8)
}

// Get Single Product
export const getSingleItem = (products, id) => {

    const items = products.find((element) => {
        return element.id === id;
    })
    return items;
}

// Get Feature Products
export const getFeatureImages = (products, type) => {

    const items = products.filter(product => {
        return product.type === type;
    })
    return items;
}


