import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import auth from "../../../api/auth";
// import Modal from 'react-responsive-modal';
// import ThemeSettings from "../../common/theme-settings"
// Import custom components
import {svgFreeShipping, svgoffer, svgservice,} from "../../../services/script"
import TrendingCollection from "../common/collection"
// import NewProduct from "../../common/new-product"
// import Instagram from "../common/instagram"
import HeaderOne from "../../common/headers/header-one"
import FooterOne from "../../common/footers/footer-one"
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {receiveUserProfile, logoutUser} from "../../../actions";

class Beauty extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    componentDidMount() {
        if (this.props.userInfo.auth_token) {
            if ((!this.props.userInfo.name) || this.props.userInfo.name === '') {
                auth.verifyTokenFromREST(res => {
                    if (res.status === 200) {
                        toast.success("Login Success!")
                        this.props.receiveUserProfile(res.data)
                    }
                    else
                        this.props.logoutUser()
                }, this.props.userInfo.auth_token)
            }
        }
    }

    render() {
        const settings = {
            fade: true,
            autoplay: true,
            autoplaySpeed: 3000
        };

        const styleBtn = {
            color: "black"
        }

        return (
            <div>
                <Helmet>
                    <title>Rajratna | Natural Products Store</title>
                </Helmet>
                <HeaderOne logoName={'layout3/logo.png'}/>
                <section className="p-0">
                    <Slider
                        className="slide-1 home-slider"
                        {...settings}
                    >
                        {/*<div>
                            <div className="home home34">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div style={{alignSelf: "flex-end"}}>
                                                    <h4>welcome to rajratna</h4>
                                                    <h1>natural products</h1>
                                                    <a href="/left-sidebar/collection" className="btn btn-outline">shop
                                                    now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div>
                            <div className="home home35">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div style={{alignSelf: "flex-end"}}>
                                                    {/*<h4>welcome to rajratna</h4>
                                                    <h1>natural products</h1>*/}
                                                    <a href="/left-sidebar/collection" className="btn btn-outline">shop
                                                        now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="home home36">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div style={{alignSelf: "flex-end"}}>
                                                    {/*<h4>welcome to rajratna</h4>
                                                    <h1>natural products</h1>*/}
                                                    <a style={styleBtn} href="/no-sidebar/product/60510dbff8c2491ab3222686" className="btn btn-outline">shop
                                                        now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </section>

                {/*Product slider*/}
                <TrendingCollection type={'trending'}/>
                {/*Product slider End*/}

                {/*More Products*/}
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="p-2 align-self-center">
                        <a
                            style={{margin: "auto"}}
                            href={"/left-sidebar/collection"}
                            className={"btn-outline align-middle"}>
                            More Products
                        </a>
                    </div>
                </div>

                {/*About Section*/}
                <section className="beauty-about">
                    <div className="container">
                        <div className="row">
                            <div style={{display: "flex"}} className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
                                <img src={"https://ik.imagekit.io/sushant9096/tr:h-455,q-100/RajRatna_Logo_6NjES4_Pd.png"}
                                     alt="" style={{maxWidth: "100%", alignSelf: "center"}} className="blur-up lazyload"/>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12">
                                <div className="about-section">
                                    <div>
                                        <h2>about us</h2>
                                        <div className="about-text">
                                            <p>We are manufacturer of soap and facial bomb and also other natural
                                                products such as face pack, utane and hair oil (for numerous hair
                                                problems). We also customize soap on client requirement.</p>
                                        </div>
                                        <div className="service small-section pb-0">
                                            <div className="row">
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>
                                                    <h5>shipping to home</h5>
                                                </div>
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{__html: svgservice}}/>
                                                    <h5>24 X 7 service</h5>
                                                </div>
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{__html: svgoffer}}/>
                                                    <h5>festival offer</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*About Section End*/}

                {/*Video Section*/}
                {/*<section className="video-section pt-4">
                    <div className="title1">
                        <h4>special offer</h4>
                        <h2 className="title-inner1">product tutorial</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <a href="javascript:void(0)" onClick={this.onOpenModal}>
                                    <div className="video-img">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/video_1.jpg`} alt="" className="img-fluid blur-up lazyload" />
                                        <div className="play-btn">
                                            <span><i className="fa fa-play" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </a>
                                <Modal
                                    open={this.state.open}
                                    onClose={this.onCloseModal}
                                    id="video"
                                    className="modal fade video-modal" center>
                                    <iframe src="https://www.youtube.com/embed/FRIDLxM8Roc"
                                            allowFullScreen />
                                </Modal>
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*Video Section End*/}

                {/*Blog Section*/}
                {/*<div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="blog p-t-0 mb-2 ratio3_2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <BlogSection />
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*Blog Section end*/}


                {/*Instagram Section*/}
                {/*<div className="section-b-space">
                    <Instagram type="watch" />
                </div>*/}
                {/*Instagram Section End*/}

                <FooterOne logoName={'layout3/logo.png'}/>

                {/*<ThemeSettings />*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
})

export default connect(
    mapStateToProps,
    {
        receiveUserProfile,
        logoutUser
    }
)(Beauty)
