import React from 'react';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    pg: {
        margin: "auto"
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#f0b54d',
        },
    },
});

export default function BasicPagination(props) {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <Pagination
                onChange={props.nextPage}
                color={"primary"}
                className={classes.pg}
                count={props.pages}
                siblingCount={0} />
        </ThemeProvider>
    );
}