import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {

    return <Link to={`${process.env.PUBLIC_URL}/`} >
                <img src={`https://ik.imagekit.io/sushant9096/tr:h-34,q-100/RajRatna_Logo_6NjES4_Pd.png`} alt="" className="img-fluid" />
            </Link>;
}

export default LogoImage;