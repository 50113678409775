import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DeleteForever, Edit} from "@material-ui/icons";

const AddressGridItem = (props) => {
    const useStyles = makeStyles((theme) => ({
        paperItem: {
            padding: theme.spacing(1),
            //width: 'max-content'
        },
        gridItem: {},
        actionsDiv: {
            //width: 'max-content',
            textAlign: "right"
        }
    }))
    const classes = useStyles()

    return (
        <Grid
            className={classes.gridItem}
            xs
            item>
            <Paper className={classes.paperItem}>
                <h6>Saved Address</h6>
                <Typography variant={"inherit"}>
                    {props.line1},<br/>
                    {props.line2},<br/>
                    {props.city},&nbsp;
                    {props.state},&nbsp;
                    {props.country},&nbsp;
                    {props.zipcode}<br/>
                    (This Address is For {props.address_type})
                </Typography>
                <div className={classes.actionsDiv}>
                    <IconButton><Edit/></IconButton>
                    <IconButton><DeleteForever/></IconButton>
                </div>
            </Paper>
        </Grid>
    )
}

export default AddressGridItem