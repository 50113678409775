import {
    CHANGE_CURRENCY, CLEAR_ALL_PRODUCTS, FETCH_NEW_PRODUCTS,
    FETCH_SINGLE_PRODUCT,
    FETCH_TRENDING_PRODUCT,
    RECEIVE_PRODUCT,
    RECEIVE_PRODUCTS
} from "../constants/ActionTypes";


const initialState = {
    products: [],
    new_products: [],
    trending_products: [],
    symbol: '₹',
    product_details: []
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ALL_PRODUCTS:
            return {
                ...state,
                products: []
            }
        case RECEIVE_PRODUCT:
            return {
                ...state,
                products: state.products.concat(action.product)
            };
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        case FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product._id === action.productId) !== -1) {
                const singleItem = state.products.reduce((itemAcc, product) => {
                    return product
                }, [])
                return {
                    ...state,
                    product_details: singleItem
                };
            }
        case FETCH_TRENDING_PRODUCT:
            return {
                ...state,
                trending_products: action.tr_products
            };

        case FETCH_NEW_PRODUCTS:
            return {
                ...state,
                new_products: action.products
            }

        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.symbol
            };
        default:
            return state;
    }
};
export default productReducer;
