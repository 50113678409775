import React, {Component} from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";

// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import {addToCart, addToCartUnsafe, addToWishlist, receiveUserProfile} from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import axios from "axios";
import {REST_URL} from "../../api/configs";
import shop from "../../api/shop";
import auth from "../../api/auth";

class NoSideBar extends Component {

    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            on_product: '',
            page_count: 0,
            page_no: 1,
            per_page: 4,
            reviews: [],
            loadingReviews: true,
            login: false,
            purchased: false
        };
    }

    authFetch(rest_res) {
        const config = {
            headers: {
                authtoken: this.props.user.auth_token
            }
        }
        axios.get(
            rest_res,
            config
        ).then((res) => {
            if (res.status === 200) {
                this.setState({
                    on_product: res.data
                })
                const token = this.props.user.auth_token
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                const data = JSON.parse(jsonPayload);
                const purchases = [...this.state.on_product.purchases]
                const findPurchase = purchases.find(item => item === data._id)
                if(findPurchase)
                {
                    this.setState({
                        purchased: true,
                        login: true
                    })
                }
                else
                {
                    this.setState({
                        login: true
                    })
                }
                shop.getProductReviews(rest_res => {
                    if (rest_res.status === 200 && rest_res.data.reviews.length !== 0)
                    {
                        this.setState({
                            reviews: rest_res.data.reviews,
                            page_count: rest_res.data.page_count,
                            loadingReviews: false
                        })
                        console.log(rest_res.data.reviews)
                    }
                    else if(rest_res.status === 200)
                    {
                        this.setState({
                            loadingReviews: false
                        })
                    }
                }, this.state.on_product._id, this.state.per_page, this.state.page_no)
            }
            document.querySelector(".loader-wrapper").style = "display: none";
        })
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        document.querySelector(".loader-wrapper").style = "display: block";
        let productId = this.props.match.params.id;
        let rest_res = REST_URL + "products/" + productId
        if(this.props.user.auth_token)
        {
            if ((!this.props.user.name) || this.props.user.name === '') {
                auth.verifyTokenFromREST(res => {
                    if (res.status === 200) {
                        this.props.receiveUserProfile(res.data)
                        this.authFetch(rest_res)
                    }
                }, this.props.user.auth_token)
            }
            else
            {
                this.authFetch(rest_res)
            }
        }
        else
        {
            axios.get(
                rest_res,
            ).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        on_product: res.data
                    })
                    shop.getProductReviews(rest_res => {
                        if (rest_res.status === 200 && rest_res.data.reviews.length !== 0)
                        {
                            this.setState({
                                reviews: rest_res.data.reviews,
                                page_count: rest_res.data.page_count,
                                loadingReviews: false
                            })
                        }
                        else if(rest_res.status === 200)
                        {
                            this.setState({
                                loadingReviews: false
                            })
                        }
                    }, this.state.on_product._id, this.state.per_page, this.state.page_no)
                }
                document.querySelector(".loader-wrapper").style = "display: none";
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.id !== this.props.match.params.id)
        {
            document.querySelector(".loader-wrapper").style = "display: block";
            let productId = this.props.match.params.id;
            let rest_res = REST_URL + "products/" + productId
            axios.default.get(
                rest_res
            ).then((res) => {
                document.querySelector(".loader-wrapper").style = "display: none";
                if (res.status === 200) {
                    this.setState({
                        on_product: res.data
                    })
                }
            })
        }
    }

    handleReviewsNextPage = (e, value) => {
        this.setState({
            page_no: value,
            loadingReviews: true
        })
        shop.getProductReviews(rest_res => {
            if (rest_res.status === 200 && rest_res.data.reviews.length !== 0)
            {
                this.setState({
                    reviews: rest_res.data.reviews,
                    page_count: rest_res.data.page_count,
                    loadingReviews: false
                })
            }
        }, this.state.on_product._id, this.state.per_page, value)
    }

    render() {
        const {symbol, addToCart, addToCartUnsafe, addToWishlist, user} = this.props
        const products = {
            fade: true
        };

        const productsnav = {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            draggable: true,
            focusOnSelect: true
        };


        return (
            <div>
                {(this.state.on_product) ?
                    <Breadcrumb title={' Product / ' + this.state.on_product.name}/>
                    :
                    ''
                }
                {/*Section Start*/}
                {
                    this.state.on_product ?
                        <section>
                            <div className="collection-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 product-thumbnail">
                                            <Slider
                                                {...products}
                                                asNavFor={this.state.nav2}
                                                ref={slider => (this.slider1 = slider)}
                                                className="product-slick">
                                                {this.state.on_product.variants ?
                                                    this.state.on_product.variants.map((vari, index) =>
                                                        <div key={index}>
                                                            <ImageZoom image={vari.images}/>
                                                        </div>
                                                    ) :
                                                    this.state.on_product.pictures.map((vari, index) =>
                                                        <div key={index}>
                                                            <ImageZoom image={vari + "?q=100&w=550"}/>
                                                        </div>
                                                    )}
                                            </Slider>
                                            <SmallImages sld2={this.slider1} item={this.state.on_product} settings={productsnav} navOne={this.state.nav1}/>
                                        </div>
                                        <DetailsWithPrice symbol={symbol} item={this.state.on_product} navOne={this.state.nav1}
                                                          addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe}
                                                          addToWishlistClicked={addToWishlist}/>
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <section>
                            <div className="container">
                                <p style={{margin: "auto"}}>Sorry, This product could not found.</p>
                            </div>
                        </section>
                }
                {/*Section End*/}
                {
                    (this.state.on_product) ?
                        <section className="tab-product m-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <DetailsTopTabs
                                            purchased={this.state.purchased}
                                            login={this.state.login}
                                            isLoading={this.state.loadingReviews}
                                            loadNextPage={this.handleReviewsNextPage}
                                            item={this.state.on_product}
                                            user={user}
                                            reviews={this.state.reviews}
                                            pages={this.state.page_count} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        null
                }
                <RelatedProduct/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //let productId = ownProps.match.params.id;
    return {
        //product: state.data.products.find(el => el._id === productId),
        symbol: state.data.symbol,
        user: state.userInfo
    }
}

export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist, receiveUserProfile})(NoSideBar);