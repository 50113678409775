import {
    DESELECT_USER_ADDRESS,
    FETCH_USER_PROFILE,
    FETCH_USER_TOKEN,
    SELECT_USER_ADDRESS,
    USER_ADDRESSES,
    USER_LOGOUT,
} from "../constants/ActionTypes";


export default function userReducer(state = {
    auth_token: '',
    name: '',
    email: '',
    phoneNumber: '',
    addresses: []
}, action) {
    switch (action.type) {
        case FETCH_USER_TOKEN:
            return {
                ...state,
                auth_token: action.token
            }

        case FETCH_USER_PROFILE:
            return {
                ...state,
                name: action.user.name,
                email: action.user.email,
                phoneNumber: action.user.phoneNumber
            }

        case USER_LOGOUT:
            return {
                auth_token: '',
                name: '',
                email: '',
                phoneNumber: ''
            }

        case USER_ADDRESSES:
            let tmp = [...action.addresses.map(address => {
                return {...address, selected: false}
            })]

            return {
                ...state,
                addresses: tmp
            }

        case SELECT_USER_ADDRESS:
            let tmp2 = [...state.addresses]
            let found = tmp2.find(item => item.selected === true)
            if(found)
            {
                let foundIndex = tmp2.findIndex(item => item === found)
                tmp2[foundIndex].selected = false
                tmp2[action.index].selected = true
            }
            else
                tmp2[action.index].selected = true

            return {
                ...state,
                addresses: tmp2
            }

        case DESELECT_USER_ADDRESS:
            let tmp_ = [...state.addresses]
            tmp_[action.index].selected = false

        default:

    }
    return state;
}