import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREMENT_QTY, CLEAR_CART, UPDATE_QTY
} from "../constants/ActionTypes";


export default function cartReducer(state = {
    cart: []
}, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product._id
            if (state.cart.findIndex(product => product._id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product._id === productId) {
                        if (product.discount !== 0) {
                            cartAcc.push({
                                ...product,
                                qty: product.qty + 1,
                                sum: (product.price - (product.price * product.discount / 100)) * (product.qty + 1)
                            }) // Increment qty
                        } else
                            cartAcc.push({...product, qty: product.qty + 1, sum: (product.price) * (product.qty + 1)}) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return {...state, cart}
            }

            return {
                ...state,
                cart: [...state.cart, {
                    ...action.product,
                    qty: action.qty,
                    sum: (action.product.discount !== 0 ? ((action.product.price - (action.product.price * action.product.discount / 100)) * action.qty) : action.product.price * action.qty)
                }]
            }

        case CLEAR_CART:
            return {...state, cart: []}

        case DECREMENT_QTY:
            if (state.cart.findIndex(product => product._id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product._id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        if (product.discount !== 0) {
                            cartAcc.push({
                                ...product,
                                qty: product.qty - 1,
                                sum: (product.price - (product.price * product.discount / 100)) * (product.qty - 1)
                            }) // Decrement qty
                        } else
                            cartAcc.push({
                                ...product,
                                qty: product.qty - 1,
                                sum: (product.price) * (product.qty - 1)
                            }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return {...state, cart}
            }

            return {
                ...state,
                cart: [...state.cart, {...action.product, qty: action.qty, sum: action.product.price * action.qty}]
            }

        case UPDATE_QTY:
            const productId2 = action.product._id
            if (state.cart.findIndex(product => product._id === productId2) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product._id === productId2) {
                        if (product.discount !== 0) {
                            cartAcc.push({
                                ...product,
                                qty: action.qty,
                                sum: (product.price - (product.price * product.discount / 100)) * (action.qty)
                            }) // Increment qty
                        } else
                            cartAcc.push({...product, qty: action.qty, sum: product.price * action.qty}) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return {...state, cart}
            }

            return {
                ...state,
                cart: [...state.cart, {
                    ...action.product,
                    qty: action.qty,
                    sum: (action.product.discount !== 0 ? ((action.product.price - (action.product.price * action.product.discount / 100)) * action.qty) : action.product.price * action.qty)
                }]
            }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item._id !== action.product_id._id)
            }

        default:
    }
    return state;
}
