import React from 'react';

function PrivacyPolicy(props) {
    return (
        <div className="wrapper">
            <div className="page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 style={{fontSize: '2rem'}}>Privacy Policy for Rajratna Natural Products</h1>
                            <p>At Rajratna Natural Products, accessible from rajratnanaturalproducts.com, one of our main
                                priorities is the privacy of our visitors. This Privacy Policy document contains
                                types of information that is collected and recorded by Rajratna Natural Products and
                                how we use it.</p>
                            <p>If you have additional questions or require more information about our Privacy
                                Policy, do not hesitate to contact us.</p>
                            <p>This Privacy Policy applies only to our online activities and is valid for visitors
                                to our website with regards to the information that they shared and/or collect in
                                Rajratna Natural Products. This policy is not applicable to any information
                                collected offline or via channels other than this website.
                            </p>
                            <h2>Consent</h2>
                            <p>By using our website, you hereby consent to our Privacy Policy and agree to its
                                terms.</p>
                            <h2>Information we collect</h2>
                            <p>The personal information that you are asked to provide, and the reasons why you are
                                asked to provide it, will be made clear to you at the point we ask you to provide
                                your personal information.</p>
                            <p>If you contact us directly, we may receive additional information about you such as
                                your name, email address, phone number, the contents of the message and/or
                                attachments you may send us, and any other information you may choose to
                                provide.</p>
                            <p>When you register for an Account, we may ask for your contact information, including
                                items such as name, company name, address, email address, and telephone number.</p>
                            <h2>How we use your information</h2>
                            <p>We use the information we collect in various ways, including to:</p>
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including
                                    for customer service, to provide you with updates and other information relating
                                    to the website, and for marketing and promotional purposes
                                </li>
                                <li>Send you emails</li><br/>
                                <li>Find and prevent fraud</li>
                            </ul>
                            <h2>Log Files</h2>
                            <p>Rajratna Natural Products follows a standard procedure of using log files. These
                                files log visitors when they visit websites. All hosting companies do this and a
                                part of hosting services' analytics. The information collected by log files include
                                internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
                                date and time stamp, referring/exit pages, and possibly the number of clicks. These
                                are not linked to any information that is personally identifiable. The purpose of
                                the information is for analyzing trends, administering the site, tracking users'
                                movement on the website, and gathering demographic information.</p>
                            <h2>Cookies</h2>
                            <p>We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</p>
                            <h2>MODIFICATION TO THIS PRIVACY POLICY</h2>
                            <p>
                                We have rights to modify this privacy policy any time and you should check it regularly on your responsibility. If your using our site that means we assume you have read our privacy policy and you are agreed on it.
                            </p>
                            <h2>SECURITY</h2>
                            <p>
                                To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                            </p>
                            <h2>PAYMENT</h2>
                            <p>
                                We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.
                                Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                                PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
                                For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com
                            </p>
                            <h2>QUESTIONS AND CONTACT INFORMATION</h2>
                            <p>
                                If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at rajratnanaturalproducts@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;