import React from 'react';
import {Link} from 'react-router-dom'

const CartHeader = ({item, total, symbol, removeFromCart}) => {
    return (
        <li>
            <div className="media">
                <Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/${item._id}`}>

                    <img alt="" className="mr-3" src={`${item.pictures[0]}?q=100&w=1000&h=736`}/>
                </Link>
                <div className="media-body">
                    <Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/${item._id}`}><h4>{item.name}</h4></Link>
                    <h4>
                        <span>{item.qty} x {symbol} {item.discount !== 0 ? (item.price - (item.price * item.discount / 100)) : item.price}</span>
                    </h4>
                </div>
            </div>
            {/*<span>{cart}</span>*/}
            <div className="close-circle">
                <a href={null} onClick={removeFromCart}><i className="fa fa-times" aria-hidden="true"/></a>
            </div>
        </li>
    )
}

export default CartHeader;
