import React from 'react';
import {CircularProgress, Dialog, DialogContent} from "@material-ui/core";
import CircularIndProgress from "./CircularIndProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modalC: {
        padding: theme.spacing(4)
    }
}))

function ProgressDialog(props) {
    const classes = useStyles()

    return (
        <Dialog
            className={classes.modalC}
            open={props.prg}>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
}

export default ProgressDialog;