import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {connect} from "react-redux"
import {receiveUserAddresses, receiveUserProfile} from "../../actions";
import auth from "../../api/auth";
import {toast} from "react-toastify";
import {Button, Grid, TextField} from "@material-ui/core";
import {Add, Close, Save} from "@material-ui/icons";
import CountrySelect from "../material-ui/CountrySelectTextField";
import AddressTypeSelectTextField from "../material-ui/AddressTypeSelectTextField";
import AddressGridItem from "../material-ui/AddressGridItem";
import SectionTypeSelectTextField from "../material-ui/SectionTypeSelectTextField";
import OrderGridItem from "../material-ui/OrderGridItem";

class Dashboard extends Component {

    sections = [
        {
            value: 0,
            label: 'My Account',
        },
        {
            value: 1,
            label: 'Address Book',
        },
        {
            value: 2,
            label: 'My Orders',
        },
    ];

    orders = [
        {
            _id: "nfsnkjfnsjkfnnsnfkjsnfjks",
            status: "paid",
            items: [
                {
                    name: "Red Wine Soap",
                    amount: 100,
                    quantity: 2
                },
                {
                    name: "Herbal Soap",
                    amount: 100,
                    quantity: 1
                }
            ],
            invoice_id: "inv_GQoDVqT5eNYB66",
            user_id: "6003df489bef945262102b7c"
        },
        {
            _id: "nfsnkjfnsjkfnnsnfkjsnfjks",
            status: "paid",
            items: [
                {
                    name: "Red Wine Soap",
                    amount: 100,
                    quantity: 2
                },
                {
                    name: "Herbal Soap",
                    amount: 100,
                    quantity: 1
                }
            ],
            invoice_id: "inv_GQoDVqT5eNYB66",
            user_id: "6003df489bef945262102b7c"
        }
    ]

    constructor(props) {
        super(props)

        this.state = {
            activeSection: 0,
            section_type: 'My Account',
            address_type: 'shipping',
            country: 'India',
            addr_line_1: '',
            addr_line_2: '',
            zipcode: '',
            city: '',
            state: '',
            showAddressForm: false,
            orders: []
        }
    }

    componentDidMount() {
        if ((!this.props.user.name) || this.props.user.name === '') {
            if (this.props.user.auth_token) {
                auth.verifyTokenFromREST(res => {
                    if (res.status === 200) {
                        toast.success("Welcome To Your Dashboard!")
                        this.props.receiveUserProfile(res.data)
                        auth.getUserAddresses(res => {
                            if (res.status === 200) {
                                this.props.receiveUserAddresses(res.data)
                            }
                        }, this.props.user.auth_token)
                    }
                }, this.props.user.auth_token)
            } else {
                toast.warn("You need to login to access your profile!")
            }
        }
        if (this.props.user.auth_token) {
            auth.verifyTokenFromREST(res => {
                if (res.status === 200) {
                    auth.getUserAddresses(res => {
                        if (res.status === 200) {
                            this.props.receiveUserAddresses(res.data)
                        }
                    }, this.props.user.auth_token)
                }
            }, this.props.user.auth_token)
        }
    }

    loadUserOrders = () => {
        auth.fetchUserOrders(response => {
            if (response.status === 200) {
                if (this.state.orders.length !== 0) {
                    this.setState({
                        orders: []
                    })
                }
                this.setState({
                    orders: response.data
                })
            } else {
                console.log(response)
            }
        }, this.props.user.auth_token, 0, 0)
    }

    setActiveSection = (index) => {
        if (typeof index === "number") {
            const val = this.sections[index].label
            this.setState({
                activeSection: index,
                section_type: val
            })
            if (index === 2) {
                this.loadUserOrders()
            }
        } else {
            let val = 0
            const found = this.sections.find(item => item.label === index)
            const foundIndex = this.sections.findIndex(item => item === found)
            val = foundIndex
            this.setState({
                activeSection: val,
                section_type: index
            })
            if (val === 2) {
                this.loadUserOrders()
            }
        }
    }

    setAddressType = (event) => {
        this.setState({
            address_type: event.target.value
        })
    }

    setCountry = (country) => {
        this.setState({
            country: country
        })
    }

    toggleShowAddressForm = () => {
        this.setState({
            showAddressForm: !this.state.showAddressForm
        })
    }

    saveAddressBtnClicked = () => {

        if (this.state.addr_line_1 === '') {
            alert("Please Fill Address Line 1 Field")
        } else if (this.state.addr_line_2 === '') {
            alert("Please Fill Address Line 2 Field")
        } else if (this.state.city === '') {
            alert("Please Fill City Field")
        } else if (this.state.city === '') {
            alert("Please Fill City Field")
        } else if (this.state.state === '') {
            alert("Please Fill State Field")
        } else if (this.state.zipcode === '') {
            alert("Please Fill Area Code Field")
        } else {
            const addr = {
                address_type: this.state.address_type,
                country: this.state.country,
                line1: this.state.addr_line_1,
                line2: this.state.addr_line_2,
                zipcode: this.state.zipcode,
                city: this.state.city,
                state: this.state.state,
            }
            auth.saveUserAddress(res => {

                if (res.status === 200) {
                    alert("Address Saved Successfully Done!")
                    this.setState({
                        address_type: 'shipping',
                        country: 'India',
                        addr_line_1: '',
                        addr_line_2: '',
                        zipcode: '',
                        city: '',
                        state: '',
                        showAddressForm: false
                    })
                    const tmp = [...this.props.user.addresses]
                    tmp.push(res.data)
                    this.props.receiveUserAddresses(tmp)
                } else {
                    alert(res.data)
                }
            }, this.props.user.auth_token, addr)
        }
    }

    setAddrLine1 = (event) => {
        this.setState({
            addr_line_1: event.target.value
        })
    }

    setAddrLine2 = (event) => {
        this.setState({
            addr_line_2: event.target.value
        })
    }

    setZipCode = (event) => {
        this.setState({
            zipcode: event.target.value
        })
    }

    setCity = (event) => {
        this.setState({
            city: event.target.value
        })
    }

    setStateIn = (event) => {
        this.setState({
            state: event.target.value
        })
    }

    render() {
        const payBtnHandler = (invoice_id) => {
            console.log(invoice_id)
            auth.paidUserOrder(response2 => {
                if (response2.status === 200) {
                    const options = {
                        "key": response2.data.key, // Enter the Key ID generated from the Dashboard
                        "currency": "INR",
                        "order_id": response2.data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        "handler": (response3) => {
                            // Below code is for successful payment
                            alert("Your Order Is Now Confirmed!")
                            this.loadUserOrders()
                        },
                        "prefill": {
                            "name": response2.data.customer_details.name,
                            "email": response2.data.customer_details.email,
                            "contact": response2.data.customer_details.contact
                        },
                        "modal": {
                            "ondismiss": function () {
                                alert("You canceled payment of this order!")
                            }
                        }
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.on('payment.failed', function (response) {
                        // alert(response.error.code);
                        // alert(response.error.description);
                        // alert(response.error.source);
                        // alert(response.error.step);
                        alert(response.error.reason);
                        // alert(response.error.metadata.order_id);
                        // alert(response.error.metadata.payment_id);
                    });
                    rzp1.open()
                } else
                    alert(response2.data.message)
            }, this.props.user.auth_token, invoice_id)
        }

        return (
            <div>
                <Breadcrumb title={'Dashboard'}/>

                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <SectionTypeSelectTextField
                                        sections={this.sections}
                                        section_type={this.state.section_type}
                                        setSection={this.setActiveSection}/>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"/> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li
                                                style={{cursor: "pointer"}}
                                                className={
                                                    this.state.activeSection === 0 ?
                                                        "active"
                                                        :
                                                        ""
                                                }
                                                onClick={() => this.setActiveSection(0)}>
                                                <a>Account Info</a>
                                            </li>
                                            <li
                                                style={{cursor: "pointer"}}
                                                className={
                                                    this.state.activeSection === 1 ?
                                                        "active"
                                                        :
                                                        ""
                                                }
                                                onClick={() => this.setActiveSection(1)}>
                                                <a>Address Book</a>
                                            </li>
                                            <li
                                                style={{cursor: "pointer"}}
                                                className={
                                                    this.state.activeSection === 2 ?
                                                        "active"
                                                        :
                                                        ""
                                                }
                                                onClick={() => this.setActiveSection(2)}>
                                                <a>My Orders</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div
                                            hidden={!(this.state.activeSection === 0)}
                                            className="welcome-msg">
                                            <p>Hello, {this.props.user.name} !</p>
                                            <p>From your My Account Dashboard you have the ability to view a snapshot of
                                                your recent account activity and update your account information. Select
                                                a link below to view or edit information.</p>
                                        </div>
                                        <div
                                            hidden={!(this.state.activeSection === 0)}
                                            className="box-account box-info">
                                            <div className="box-head">
                                                <h2>Account Information</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Contact Information</h3>
                                                            {/*<a role="button">Edit</a>*/}
                                                        </div>
                                                        <div className="box-content">
                                                            <h6>{this.props.user.name}</h6>
                                                            <h6>{this.props.user.email}</h6>
                                                            {/*<h6><a role="button">Change Password</a></h6>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Newsletters</h3>
                                                            {/*<a role="button">Edit</a>*/}
                                                        </div>
                                                        <div className="box-content">
                                                            <p>
                                                                You are currently not subscribed to any newsletter.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3>Address Book</h3>
                                                        <a role="button" onClick={() => this.setActiveSection(1)}>Manage
                                                            Addresses</a>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h6>Default Billing Address</h6>
                                                            <address>
                                                                You have not set a default billing address.<br/>
                                                                {/*<a role="button">Edit Address</a>*/}
                                                            </address>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h6>Default Shipping Address</h6>
                                                            <address>
                                                                You have not set a default shipping address.<br/>
                                                                {/*<a role="button">Edit Address</a>*/}
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="box-account box-info"
                                            hidden={!(this.state.activeSection === 1)}>
                                            <div className="box-title mt-1">
                                                <div className="row">
                                                    <Grid
                                                        className="col-12"
                                                        container
                                                        spacing={1}
                                                        direction={"row"}>
                                                        {
                                                            this.props.user.addresses ?
                                                                this.props.user.addresses.map(
                                                                    (item, index) =>
                                                                        <AddressGridItem
                                                                            key={index}
                                                                            {...item}/>
                                                                )
                                                                :
                                                                <h5>Your Address Book is Empty.</h5>
                                                        }
                                                    </Grid>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 my-1">
                                                        <Button
                                                            variant={"text"}
                                                            color={"primary"}
                                                            className="float-right"
                                                            startIcon={this.state.showAddressForm ?
                                                                <Close/>
                                                                :
                                                                <Add/>
                                                            }
                                                            onClick={() => this.toggleShowAddressForm()}>
                                                            {
                                                                this.state.showAddressForm ?
                                                                    "Cancel Add Address"
                                                                    :
                                                                    "Add New Address"
                                                            }
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div hidden={!(this.state.showAddressForm)} className="row my-1">
                                                    <div className="col-md-4 my-1">
                                                        <TextField
                                                            fullWidth={true}
                                                            label="Address Line 1"
                                                            variant="outlined"
                                                            onChange={this.setAddrLine1}
                                                            value={this.state.addr_line_1}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <TextField
                                                            fullWidth={true}
                                                            label="Address Line 2"
                                                            variant="outlined"
                                                            onChange={this.setAddrLine2}
                                                            value={this.state.addr_line_2}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <TextField
                                                            fullWidth={true}
                                                            label="City"
                                                            variant="outlined"
                                                            onChange={this.setCity}
                                                            value={this.state.city}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <TextField
                                                            fullWidth={true}
                                                            label="State"
                                                            variant="outlined"
                                                            onChange={this.setStateIn}
                                                            value={this.state.state}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <TextField
                                                            fullWidth={true}
                                                            label="Area Pincode"
                                                            type="number"
                                                            variant="outlined"
                                                            onChange={this.setZipCode}
                                                            value={this.state.zipcode}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <CountrySelect
                                                            setCountry={this.setCountry}/>
                                                    </div>
                                                    <div className="col-md-4 my-1">
                                                        <AddressTypeSelectTextField
                                                            setAddressType={this.setAddressType}
                                                            address_type={this.state.address_type}/>
                                                    </div>
                                                </div>
                                                <div
                                                    hidden={!(this.state.showAddressForm)}
                                                    className="row text-center">
                                                    <div className="col-12 my-1">
                                                        <Button
                                                            variant={"contained"}
                                                            color={"primary"}
                                                            startIcon={<Save/>}
                                                            onClick={() => this.saveAddressBtnClicked()}>
                                                            Save Address
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="box-account box-info"
                                            hidden={!(this.state.activeSection === 2)}>
                                            <div className="box-title mt-2">
                                                <div /*className="row"*/>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        //className="col"
                                                        direction={"column"}>
                                                        {
                                                            this.state.orders.map((item, index) =>
                                                                <OrderGridItem
                                                                    payBtnClick={payBtnHandler}
                                                                    key={index}
                                                                    data={item}
                                                                />
                                                            )
                                                        }
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userInfo,
})

export default connect(
    mapStateToProps,
    {receiveUserProfile, receiveUserAddresses}
)(Dashboard)