import React, {Component} from 'react';
import { connect } from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';


import {getBrands, getCategories, getColors, getMinMaxPrice, getVisibleProducts} from '../../../services';
import {filterBrand, filterCategory, filterColor, filterPrice} from '../../../actions'

class Filter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFilter: false
        }
    }

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    }

    clickBrandHandle(event, brands) {

        const index = brands.indexOf(event.target.value);
        if (event.target.checked)
            brands.push(event.target.value); // push in array checked value
        else
            brands.splice(index, 1); // removed in array unchecked value

        this.props.filterBrand(brands);
    }

    clickCategoryHandle(event, categories) {

        const index = categories.indexOf(event.target.value);
        if (event.target.checked)
            categories.push(event.target.value); // push in array checked value
        else
            categories.splice(index, 1); // removed in array unchecked value
        this.props.filterCategory(categories);
        this.props.getVisibleProducts([], 3, this.props.filters)
    }

    colorHandle(event, color){
        const elems = document.querySelectorAll(".color-selector ul li");
        [].forEach.call(elems, function(el) {
            el.classList.remove("active");
        });
        event.target.classList.add('active');
        this.props.filterColor(color)
    }

    render (){
        const filteredBrands = this.props.filters.brand;
        const filteredCategories = this.props.filters.category;
        //console.log(this.props.brands);
        return (
                <div className="collection-filter-block">
                    {/*brand filter start*/}
                    <div className="collection-mobile-back">
                        <span className="filter-back" onClick={(e) => this.closeFilter(e)} >
                            <i className="fa fa-angle-left" aria-hidden="true"/> back
                        </span>
                    </div>
                    {/*<SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>brand</h3>
                                <div className="collection-collapse-block-content"  ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        {this.props.brands.map((brand, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input type="checkbox" onClick={(e) => this.clickBrandHandle(e,filteredBrands)} value={brand} defaultChecked={filteredBrands.includes(brand)} className="custom-control-input" id={brand} />
                                                    <label className="custom-control-label"
                                                           htmlFor={brand}>{brand}</label>
                                                </div> )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>*/}
                    {/*category filter start here */}
                    <SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>category</h3>
                                <div className="collection-collapse-block-content"  ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        {this.props.categories.map((category, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input type="checkbox" onClick={(e) => this.clickCategoryHandle(e,filteredCategories)} value={category} defaultChecked={filteredCategories.includes(category)} className="custom-control-input" id={category} />
                                                    <label className="custom-control-label"
                                                           htmlFor={category}>{category}</label>
                                                </div> )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                    {/*color filter start here*/}
                    {/*
                        this.props.colors.length === 0 ?
                            null
                            :
                            <SlideToggle>
                                {({onToggle, setCollapsibleElement}) => (
                                    <div className="collection-collapse-block">
                                        <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>
                                        <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                            <div className="color-selector">
                                                <ul>
                                                    {this.props.colors.map((color, index) => {
                                                        return (
                                                            <li className={color} title={color} onClick={(e) => this.colorHandle(e, color)} key={index}/> )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SlideToggle>
                    */}
                    {/*price filter start here */}
                    <SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block open">
                                <h3 className="collapse-block-title" onClick={onToggle}>price</h3>
                                <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        <div className="custom-control custom-checkbox collection-filter-checkbox">
                                            <InputRange
                                                maxValue={this.props.prices.max}
                                                minValue={this.props.prices.min}
                                                value={this.props.filters.value}
                                                onChange={value => {
                                                    this.props.filterPrice({ value })
                                                    setTimeout(() => {
                                                        this.props.getVisibleProducts([], 5, this.props.filters)
                                                    }, 500)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                </div>
        )
    }
}


const mapStateToProps = state => ({
    /*brands: getBrands(state.data.products),
    colors: getColors(state.data.products),*/
    categories: getCategories(),
    prices: getMinMaxPrice(),
    filters: state.filters
})

export default connect(
    mapStateToProps,
    { getVisibleProducts, filterCategory, filterBrand, filterColor, filterPrice }
)(Filter);