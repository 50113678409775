import React, {Component} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import HalfRating from "../../material-ui/HalfRating";
import {Paper, Typography} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import UserAvatar from "../../material-ui/UserAvatar";
import BasicPagination from "../../material-ui/BasicPagination";
import CircularIndProgress from "../../material-ui/CircularIndProgress";
import auth from "../../../api/auth";
import {toast} from "react-toastify";

class DetailsTopTabs extends Component {
    state = {
        review_title: 'Good',
        review_desc: '',
        review_rate: 3,
    }

    render() {
        const {login, purchased, item, user} = this.props
        const handleRevTitleChange = (val) => {
            this.setState({
                review_title: val
            })
        }

        const handleRevDesChange = (event) => {
            this.setState({
                review_desc: event.target.value
            })
        }

        const handleRevRatChange = (value) => {
            if(value >= 0.5)
            {
                this.setState({
                    review_rate: parseFloat(value)
                })
            }

            if(value === 0.5){
                handleRevTitleChange("Very Worst")
            }
            else if(value === 1){
                handleRevTitleChange("Worst")
            }
            else if(value === 1.5){
                handleRevTitleChange("Very Bad")
            }
            else if(value === 2){
                handleRevTitleChange("Bad")
            }
            else if(value === 2.5){
                handleRevTitleChange("Not Bad")
            }
            else if(value === 3){
                handleRevTitleChange("Good")
            }
            else if(value === 3.5){
                handleRevTitleChange("Very Good")
            }
            else if(value === 4){
                handleRevTitleChange("Excellent")
            }
            else if(value === 4.5){
                handleRevTitleChange("Very Excellent")
            }
            else if(value === 5) {
                handleRevTitleChange("Very Excellent and Perfect")
            }
        }

        const submitReview = () => {
            const title = this.state.review_title
            const description = this.state.review_desc
            const rating = this.state.review_rate
            if(description === '')
            {
                alert("Please describe what you fill about this product!")
            }
            else
            {
                const review = {
                    product_id: item._id,
                    title: title,
                    description: description,
                    rating: rating
                }
                auth.submitReview(res => {
                    if(res.status === 200)
                    {
                        alert(res.data)
                    }
                    else if(res.status === 299)
                    {
                        alert(res.data)
                    }
                }, user.auth_token, review)
            }
        }

        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link"><i
                                        className="icofont icofont-man-in-glasses"/>Details</span>
                                    <div className="material-border"/>
                                </Tab>
                                {/*<Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-contacts"/>Video</span>
                                    <div className="material-border"/>
                                </Tab>*/}
                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-contacts"/>Write Review</span>
                                    <div className="material-border"/>
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-contacts"/>Reviews</span>
                                    <div className="material-border"/>
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <p className="mt-4 p-0">
                                    {
                                        this.props.item.description ?
                                            this.props.item.description
                                            :
                                            "No Details are Available for this Product."
                                    }
                                </p>
                            </TabPanel>
                            {/*<TabPanel>
                                <div className="mt-4 text-center">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            title="Overview"
                                            src="https://www.youtube.com/embed/BUWzX78Ye_8"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen/>
                                    </div>
                                </div>
                            </TabPanel>*/}
                            <TabPanel>
                                <form className="theme-form mt-4">
                                    {
                                        login !== true ?
                                            <div className="form-row">
                                                You need to login to give review!
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        purchased === true ?
                                            <div className="form-row">
                                                <div className="col-md-12 ">
                                                    <div className="media m-0">
                                                        <label>Rating</label>
                                                        <div className="media-body ml-3">
                                                            <HalfRating
                                                                rating={this.state.review_rate}
                                                                change={handleRevRatChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="review">Review Title</label>
                                                    <input
                                                        readOnly
                                                        value={this.state.review_title}
                                                        onChange={handleRevTitleChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="review_title"
                                                        placeholder="Enter your Review title"
                                                        required/>
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="review">Review Description</label>
                                                    <textarea
                                                        value={this.state.review_desc}
                                                        onChange={handleRevDesChange}
                                                        className="form-control"
                                                        placeholder="Write Your Testimonial Here"
                                                        id="exampleFormControlTextarea1"
                                                        rows="6"/>
                                                </div>
                                                <div className="col-md-12">
                                                    <button
                                                        onClick={() => submitReview()}
                                                        className="btn btn-solid"
                                                        type="button">
                                                        Submit Your Review
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-row">
                                                You need to purchase this product to review it!
                                            </div>
                                    }
                                </form>
                            </TabPanel>
                            <TabPanel>
                                <div className="mt-4 col-md-12">
                                    <div
                                        hidden={this.props.isLoading}
                                        className="row">
                                        {
                                            this.props.reviews.length === 0 ?
                                                "This product is not yet reviewed by anyone!"
                                                :
                                                null
                                        }
                                        {
                                            this.props.reviews.map(
                                                (data, index) =>
                                                    <div
                                                        key={index}
                                                        className="col-md-4 mb-1">
                                                        <Paper>
                                                            <div className="row">
                                                                {/*<div className="col-3">
                                                                    <div
                                                                        className="pl-1 py-1">
                                                                        <UserAvatar/>
                                                                    </div>
                                                                </div>*/}
                                                                <div className="col-12">
                                                                    <div className="mx-1 py-1">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <Rating
                                                                                    precision={0.5}
                                                                                    value={data.rating}
                                                                                    readOnly/>
                                                                            </div>
                                                                            <div className="col">
                                                                                <Typography
                                                                                    style={{fontWeight: 700}}
                                                                                    align={"left"}
                                                                                    variant={"caption"}>
                                                                                    {data.title}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row pl-1">
                                                                            <div className="col">
                                                                                <Typography
                                                                                    style={{color: "#4a4040"}}
                                                                                    component={"h6"}
                                                                                    variant={"body1"}>
                                                                                    {data.description}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    </div>
                                            )
                                        }
                                    </div>
                                    <div
                                        hidden={!this.props.isLoading}
                                        className="mt-4 col-md-12">
                                        <CircularIndProgress />
                                    </div>
                                </div>
                                <div className="mt-4 mx-md-auto col-md-12 col-sm-8 w-100">
                                    <BasicPagination
                                        nextPage={this.props.loadNextPage}
                                        pages={this.props.pages}/>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

export default DetailsTopTabs;
