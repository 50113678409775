import {Button, Dialog, DialogContent, TextField, Tabs, Tab} from "@material-ui/core"
import React from 'react'
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import Slide from '@material-ui/core/Slide';
import {orange} from "@material-ui/core/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#f0b44d"
        },
        secondary: {
            main: orange["A200"]
        }
    }
})

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: 4
    },
    textField: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    tabsStyle: {
        marginBottom: theme.spacing(2),
    }
}))

const AuthDialog = (props) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0)
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                transitionDuration={500}
                TransitionComponent={Transition}
                className={classes.root}
                open={props.open}>
                <DialogContent>
                    <Tabs
                        indicatorColor={"primary"}
                        classes={{root: classes.tabsStyle}}
                        value={activeTab}
                        onChange={handleTabChange}
                        variant={"fullWidth"}>
                        <Tab value={0} label={"Login"}/>
                        <Tab value={1} label={"Sign Up"}/>
                    </Tabs>
                    <form>
                        {
                            activeTab === 0 ?
                                <React.Fragment>
                                    <TextField
                                        className={classes.textField}
                                        label={"Email"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        type={"email"}
                                        value={props.email}
                                        onChange={props.emailChange}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label={"Password"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        value={props.password}
                                        onChange={props.passwordChange}
                                        type={"password"}
                                    />
                                    <Button
                                        onClick={props.lgClicked}
                                        color="primary"
                                        variant={"contained"}
                                        className={classes.button}>
                                        Login
                                    </Button>
                                    <Button
                                        onClick={props.cnClicked}
                                        color="secondary"
                                        variant={"outlined"}
                                        className={classes.button}>
                                        Cancel Checkout
                                    </Button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <TextField
                                        className={classes.textField}
                                        label={"First and Last Name"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        value={props.name}
                                        onChange={props.nameChange}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label={"Email"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        type={"email"}
                                        value={props.email}
                                        onChange={props.emailChange}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label={"Phone Number"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        value={props.phone}
                                        onChange={props.phoneChange}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        label={"Password"}
                                        variant={"outlined"}
                                        fullWidth
                                        required
                                        value={props.password}
                                        onChange={props.passwordChange}
                                        type={"password"}
                                    />
                                    <Button
                                        onClick={props.rgClicked}
                                        color="primary"
                                        variant={"contained"}
                                        className={classes.button}>
                                        Register
                                    </Button>
                                    <Button
                                        onClick={props.cnClicked}
                                        color="secondary"
                                        variant={"outlined"}
                                        className={classes.button}>
                                        Cancel Checkout
                                    </Button>
                                </React.Fragment>
                        }
                    </form>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
}

export default AuthDialog