import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import axios from 'axios'
import {REST_URL} from "../../api/configs";

class Register extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            password: '',
            phoneNumber: '',
            OTP: '',
            session_id: ''
        }

        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        const obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    createAccountBtn = () => {
        if (this.validator.allValid()) {
            const data = JSON.stringify({
                "name": this.state.name,
                "email": this.state.email,
                "phoneNumber": this.state.phoneNumber,
                "password": this.state.password
            });

            const config = {
                method: 'post',
                url: REST_URL + 'auth/registerUser',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.status === 200) {
                        alert("Your Registration Successful!")
                        window.open("/pages/login", "_self")
                    } else
                        alert(response.data)
                })
                .catch(function (error) {
                    alert(error);
                });
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    /*    verifyOTPBtn = () => {

        }

        sendOTPBtn = () => {

        }*/

    render() {

        return (
            <div>
                <Breadcrumb title={'create account'}/>

                {/*Register section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>create account</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    onChange={this.setStateFromInput}
                                                    value={this.state.name}
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter Name"/>
                                                {this.validator.message('name', this.state.name, 'required|alpha_space')}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="pno">Phone Number (Must Be Indian)</label>
                                                <input
                                                    onChange={this.setStateFromInput}
                                                    value={this.state.phoneNumber}
                                                    type="text"
                                                    className="form-control"
                                                    id="pno"
                                                    name="phoneNumber"
                                                    placeholder="Enter Phone Number"/>
                                                {this.validator.message('phoneNumber', this.state.phoneNumber, 'required|min:10|max:10|phone')}
                                            </div>
                                        </div>
                                        {/*<div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="otp">OTP</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="otp"
                                                    placeholder="Enter OTP Number"/>
                                                {this.validator.message('otp', this.state.OTP, 'required|min:6|max:6|integer')}
                                            </div>
                                            <div className="form-group col">
                                                <button
                                                    onClick={() => this.verifyOTPBtn()}
                                                    type={"button"}
                                                    className="btn btn-solid mt-md-4">
                                                    Verify OTP
                                                </button>
                                            </div>
                                            <div className="form-group col">
                                                <button
                                                    onClick={() => this.sendOTPBtn()}
                                                    type={"button"}
                                                    className="btn btn-solid mt-md-4">
                                                    Send OTP
                                                </button>
                                            </div>
                                        </div>*/}
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input
                                                    onChange={this.setStateFromInput}
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email" required=""/>
                                                {this.validator.message('email', this.state.email, 'required|email')}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    onChange={this.setStateFromInput}
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    placeholder="Enter your password"
                                                    required=""/>
                                                {this.validator.message('password', this.state.password, 'required|alpha_num_dash')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col">
                                                <button type={"button"} onClick={() => this.createAccountBtn()}
                                                        className="btn btn-solid">create Account
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Register