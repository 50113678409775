import React, { Component } from 'react';
import Slider from 'react-slick';
import Loader from "react-loader-spinner";

class SmallImages extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nav2: null
        }
    }
    componentDidMount() {
        this.setState({
            nav2: this.slider2
        });
    }

    render() {
        const { item, settings, sld2 } = this.props;

        const productsnav = settings;
        const imgClick = (idx) => {
            sld2.slickGoTo(idx)
        }

        const ProductImage = (props) => {
            const {vari, index} = props
            const [loading, setLoading] = React.useState(true)

            return (
                <React.Fragment>
                    {
                        loading &&
                        <Loader
                            style={{
                                // width: "10%",
                                margin: "0 auto",
                                // border: "red"
                            }}
                            // className={"pt-1"}
                            type="Rings"
                            color="#f0b54d"
                            height={"10vh"}
                            width={"25vw"}
                            // timeout={3000} //3 secs
                        />
                    }
                    <img
                        onLoad={() => setTimeout(() => setLoading(false), 500)}
                        style={{
                            display: loading ? "none" : "block"
                        }}
                        src={`${vari}?q=100&w=200`}
                        alt=""
                        className="img-fluid"
                        onClick={() => imgClick(index)}
                      />
                </React.Fragment>
            )
        }

        return (
            <div className="row">
                <div className="col-12 p-0">
                    <Slider {...productsnav} asNavFor={this.props.navOne} ref={slider => (this.slider2 = slider)} className="slider-nav">
                        {item.variants?
                        item.variants.map((vari, index) =>
                            <div key={index}>
                                <img src={`${vari.images}`} key={index} alt=""  className="img-fluid" />
                            </div>
                        ):
                            item.pictures.map((vari, index) =>
                                <div key={index}>
                                    <ProductImage vari={vari} index={index} />
                                </div>
                            )}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default SmallImages;