import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    large: {
        width: 64,
        height: 64,
    },
}));

export default function UserAvatar() {
    const classes = useStyles();

    return (
        <Avatar className={classes.large}>
            S
        </Avatar>
    );
}