import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import SimpleReactValidator from 'simple-react-validator';
import Breadcrumb from "../common/breadcrumb";
import {
    clearCart,
    deselectUserAddresses,
    fetchUserToken,
    receiveUserAddresses,
    receiveUserProfile,
    removeFromWishlist,
    selectUserAddresses,
    logoutUser, registerUser
} from '../../actions'
import {getCartTotal} from "../../services";
import AuthDialog from "../material-ui/AuthDialog";
import auth from "../../api/auth";
import {toast} from "react-toastify";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import ChkAddressGridItem from "../material-ui/ChkAddressGridItem";
import {Add, Close, Save} from "@material-ui/icons";
import CountrySelect from "../material-ui/CountrySelectTextField";
import AddressTypeSelectTextField from "../material-ui/AddressTypeSelectTextField";
import shop from "../../api/shop";
import ProgressDialog from "../material-ui/ProgressDialog";

class checkOut extends Component {

    constructor(props) {
        super(props)

        this.state = {
            payment: 'stripe',
            name: '',
            phone: '',
            email: '',
            password: "",
            create_account: '',
            showAuthDialog: false,
            address_type: 'shipping',
            country: 'India',
            addr_line_1: '',
            addr_line_2: '',
            zipcode: '',
            city: '',
            state: '',
            showAddressForm: false,
            shipping_charge: 0,
            progress: false,
            invoice_id: ''
        }
        this.validator = new SimpleReactValidator();
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    setAddressType = (event) => {
        this.setState({
            address_type: event.target.value
        })
    }

    setCountry = (country) => {
        this.setState({
            country: country
        })
    }

    toggleShowAddressForm = () => {
        this.setState({
            showAddressForm: !this.state.showAddressForm
        })
    }

    saveAddressBtnClicked = () => {

        if (this.state.addr_line_1 === '')
        {
            alert("Please Fill Address Line 1 Field")
        }
        else if (this.state.addr_line_2 === '')
        {
            alert("Please Fill Address Line 2 Field")
        }
        else if (this.state.city === '')
        {
            alert("Please Fill City Field")
        }
        else if (this.state.city === '')
        {
            alert("Please Fill City Field")
        }
        else if (this.state.state === '')
        {
            alert("Please Fill State Field")
        }
        else if (this.state.zipcode === '')
        {
            alert("Please Fill Area Code Field")
        }
        else
        {
            const addr = {
                address_type: this.state.address_type,
                country: this.state.country,
                line1: this.state.addr_line_1,
                line2: this.state.addr_line_2,
                zipcode: this.state.zipcode,
                city: this.state.city,
                state: this.state.state,
            }
            auth.saveUserAddress(res => {

                if (res.status === 200)
                {
                    alert("Address Saved Successfully Done!")
                    this.setState({
                        address_type: 'shipping',
                        country: 'India',
                        addr_line_1: '',
                        addr_line_2: '',
                        zipcode: '',
                        city: '',
                        state: '',
                        showAddressForm: false
                    })
                    const tmp = [...this.props.userInfo.addresses]
                    tmp.push(res.data)
                    this.props.receiveUserAddresses(tmp)
                }
                else
                {
                    alert(res.data)
                }
            }, this.props.userInfo.auth_token, addr)
        }
    }

    setAddrLine1 = (event) => {
        this.setState({
            addr_line_1: event.target.value
        })
    }

    setAddrLine2 = (event) => {
        this.setState({
            addr_line_2: event.target.value
        })
    }

    setZipCode = (event) => {
        this.setState({
            zipcode: event.target.value
        })
    }

    setCity = (event) => {
        this.setState({
            city: event.target.value
        })
    }

    setStateIn = (event) => {
        this.setState({
            state: event.target.value
        })
    }

    componentDidMount() {
        if (this.props.cartItems.length === 0)
        {
            window.open("/", "_self")
        }
        this.setState({
            progress: true
        })
        if (this.props.userInfo.auth_token)
        {
            if ((!this.props.userInfo.name) || this.props.userInfo.name === '')
            {
                auth.verifyTokenFromREST(res => {
                    if (res.status === 200)
                    {
                        toast.success("Login Success!")
                        this.props.receiveUserProfile(res.data)
                        auth.getUserAddresses(res => {
                            if (res.status === 200)
                            {
                                this.props.receiveUserAddresses(res.data)
                            }
                        }, this.props.userInfo.auth_token)
                    }
                    else
                    {
                        this.props.logoutUser()
                    }
                }, this.props.userInfo.auth_token)
            }
            if (!this.props.userInfo.addresses)
            {
                auth.getUserAddresses(res => {
                    if (res.status === 200)
                    {
                        this.props.receiveUserAddresses(res.data)
                    }
                }, this.props.userInfo.auth_token)
            }
            if (this.props.userInfo.addresses)
            {
                const foundSelected = this.props.userInfo.addresses.find(item => item.selected === true)
                if (foundSelected !== undefined)
                {
                    const foundSelectedIndex = this.props.userInfo.addresses.findIndex(item => item === foundSelected)
                    this.props.deselectUserAddresses(foundSelectedIndex)
                }
            }
        }
        else
        {
            this.setState({
                showAuthDialog: true
            })
        }
        this.setState({
            progress: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userInfo !== this.props.userInfo && this.props.userInfo.auth_token === '')
        {
            this.setState({
                showAuthDialog: true
            })
        }
        if (prevProps.userInfo.auth_token !== this.props.userInfo.auth_token && this.props.userInfo.auth_token !== '')
        {
            auth.getUserAddresses(res => {
                if (res.status === 200)
                {
                    this.props.receiveUserAddresses(res.data)
                }
            }, this.props.userInfo.auth_token)
            this.setState({
                showAuthDialog: false
            })
        }
        if(prevProps.cartItems !== this.props.cartItems && this.props.cartItems.length === 0)
        {
            this.props.history.push({
                pathname: '/',
            })
        }
    }

    render() {
        const {cartItems, symbol, total} = this.props;

        const handleNameTextChanged = (event) => {
            this.setState({
                name: event.target.value
            })
        }

        const handleEmailTextChanged = (event) => {
            this.setState({
                email: event.target.value
            })
        }

        const handlePasswordTextChanged = (event) => {
            this.setState({
                password: event.target.value
            })
        }

        const handlePhoneTextChanged = (event) => {
            this.setState({
                phone: event.target.value
            })
        }

        const handleLoginBtnClicked = () => {
            if (this.state.email === '')
            {
                alert("Email Not Filled!")
            }
            else if (this.state.password === '')
            {
                alert("Password Not Filled!")
            }
            else
            {
                this.props.fetchUserToken(this.state.email, this.state.password)
            }
        }

        const handleRegBtnClicked = () => {
            if (this.state.name === '')
            {
                alert("First Name and Last Name not filled!")
            }
            else if (this.state.name.length < 2)
            {
                alert("First Name and Last Name must be at least 2 characters long!")
            }
            else if (this.state.email === '')
            {
                alert("Email not filled!")
            }
            else if (this.state.phone === "")
            {
                alert("Phone number not filled!")
            }
            else if (this.state.phone.length !== 10)
            {
                alert("Phone number must be 10 digit!")
            }
            else if (this.state.password === '')
            {
                alert("Password not filled!")
            }
            else if (this.state.password.length < 6)
            {
                alert("Password must be at least 6 digit!")
            }
            else
            {
                this.props.registerUser(
                    this.state.name,
                    this.state.email,
                    this.state.phone,
                    this.state.password
                )
            }
        }

        const handleCancelBtnClicked = () => {
            this.setState({
                showAuthDialog: !this.state.showAuthDialog
            })
            window.open("/", "_self")
        }

        const handleAddressClicked = async (index, item) => {
            this.setState({
                progress: true
            })
            let total_weight = 0
            this.props.cartItems.forEach(item => {
                total_weight = total_weight + ((item.weight * item.qty) / 1000)
            })

            shop.getShippingCharges(res => {
                if (res.status === 200)
                {
                    this.setState({
                        shipping_charge: res.data.rate
                    })
                    this.props.selectUserAddresses(index)
                }
                else
                {
                    if (res.data.hasOwnProperty('delivery_postcode'))
                    {
                        alert(res.data.delivery_postcode[0])
                    }
                    else if (res.data.hasOwnProperty('weight'))
                    {
                        alert(res.data.weight[0])
                    }
                    else if (res.data.hasOwnProperty('cod'))
                    {
                        alert(res.data.cod[0])
                    }
                    else if (res.data.hasOwnProperty('order_id'))
                    {
                        alert(res.data.order_id[0])
                    }
                    else
                        alert("Something Went Wrong Please Try To Select Another Address!")
                }
            }, this.props.userInfo.addresses[index].zipcode, total_weight, this.props.userInfo.auth_token)

            setTimeout(() => {
                this.setState({
                    progress: false
                })
            }, 500)
        }

        const disableButton = async () => {
            const placeOrdBtn = document.getElementById('placeOrdBtn')
            placeOrdBtn.disabled = true;
            setTimeout(() => {
                placeOrdBtn.disabled = false;
            }, 3000)
        }

        const handlePlaceOrderClick = () => {
            disableButton()
            const found = this.props.userInfo.addresses.find(address => address.selected === true)
            if (found !== undefined)
            {
                const line_items = []
                this.props.cart.forEach(item => {
                    line_items.push({
                        id: item._id,
                        currency: "INR",
                        quantity: item.qty
                    })
                })
                const order = {
                    shipping_charge: parseInt(this.state.shipping_charge),
                    billing_address: {
                        line1: found.line1,
                        line2: found.line2,
                        zipcode: found.zipcode,
                        city: found.city,
                        state: found.state,
                        country: found.country
                    },
                    shipping_address: {
                        line1: found.line1,
                        line2: found.line2,
                        zipcode: found.zipcode,
                        city: found.city,
                        state: found.state,
                        country: found.country
                    },
                    line_items: line_items
                }

                let cart = [...cartItems]
                let orderTotal = total

                if (this.state.invoice_id === '')
                {

                    auth.createUserOrder(response => {
                        if (response.status === 200)
                        {
                            this.props.clearCart()
                            const invoice_id = response.data.invoice_id
                            this.setState({
                                invoice_id: invoice_id
                            })
                            handlePayOrder(invoice_id, found, cart, orderTotal)
                        }
                        else
                            alert(response.data)
                    }, this.props.userInfo.auth_token, order)
                }
                else
                    handlePayOrder(this.state.invoice_id, found)
            }
            else
                alert("Please Select Your Shipping Address.")
        }

        const handlePayOrder = (invoice_id, address, cart, orderTotal) => {
            auth.paidUserOrder(response2 => {
                if (response2.status === 200)
                {
                    const options = {
                        "key": response2.data.key, // Enter the Key ID generated from the Dashboard
                        "currency": "INR",
                        "order_id": response2.data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        "handler": (response3) => {
                            // Below code is for successful payment
                            this.props.history.push({
                                pathname: '/order-success',
                                state: {
                                    payment: {
                                        paymentID: invoice_id,
                                    },
                                    items: cart,
                                    orderTotal: orderTotal,
                                    symbol: symbol,
                                    address: address,
                                    shipping_charge: parseInt(this.state.shipping_charge),
                                    phone: this.props.userInfo.phoneNumber
                                }
                            })
                        },
                        "prefill": {
                            "name": response2.data.customer_details.name,
                            "email": response2.data.customer_details.email,
                            "contact": response2.data.customer_details.contact
                        },
                        "modal": {
                            "ondismiss": function () {
                                alert("Go to My Profile -> My Orders to complete payment of this order later!")
                                window.open("/", "_self")
                            }
                        }
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.on('payment.failed', function (response) {
                        alert(response.error.code);
                        alert(response.error.description);
                        /*alert(response.error.source);
                        alert(response.error.step);
                        alert(response.error.reason);
                        alert(response.error.metadata.order_id);
                        alert(response.error.metadata.payment_id);*/
                    });
                    rzp1.open()
                }
                else
                    alert(response2.data.message)
            }, this.props.userInfo.auth_token, invoice_id)
        }

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Rajratna Natural Products | CheckOut Page</title>
                    <meta name="description"
                          content="Rajratna – Multipurpose Shopping Site"/>
                </Helmet>
                {/*SEO Support End */}

                <ProgressDialog prg={this.state.progress}/>

                <AuthDialog
                    lgClicked={handleLoginBtnClicked}
                    cnClicked={handleCancelBtnClicked}
                    rgClicked={handleRegBtnClicked}
                    name={this.state.name}
                    nameChange={handleNameTextChanged}
                    email={this.state.email}
                    emailChange={handleEmailTextChanged}
                    password={this.state.password}
                    passwordChange={handlePasswordTextChanged}
                    phone={this.state.phone}
                    phoneChange={handlePhoneTextChanged}
                    open={this.state.showAuthDialog}
                />

                <Breadcrumb title={'Checkout'}/>

                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <div>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-title">
                                                <h3>Billing Details</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <Typography
                                                        style={
                                                            {
                                                                fontSize: 16
                                                            }
                                                        }
                                                        variant={"h6"}>
                                                        Please Select Your Shipping Address
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Grid
                                                    className="col-12"
                                                    container
                                                    spacing={1}
                                                    direction={"row"}>
                                                    {
                                                        this.props.userInfo.addresses ?
                                                            this.props.userInfo.addresses.map(
                                                                (item, index) =>
                                                                    <ChkAddressGridItem
                                                                        key={index}
                                                                        clicked={() => handleAddressClicked(index, item)}
                                                                        {...item}/>
                                                            )
                                                            :
                                                            <h5>Your Address Book is Empty.</h5>
                                                    }
                                                </Grid>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 my-1">
                                                    <Button
                                                        variant={"text"}
                                                        color={"primary"}
                                                        className="float-right"
                                                        startIcon={this.state.showAddressForm ?
                                                            <Close/>
                                                            :
                                                            <Add/>
                                                        }
                                                        onClick={() => this.toggleShowAddressForm()}>
                                                        {
                                                            this.state.showAddressForm ?
                                                                "Cancel Add Address"
                                                                :
                                                                "Add New Address"
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                            <div hidden={!(this.state.showAddressForm)} className="row my-1">
                                                <div>
                                                    <TextField
                                                        className={"mb-2"}
                                                        fullWidth={true}
                                                        label="Address Line 1"
                                                        variant="outlined"
                                                        onChange={this.setAddrLine1}
                                                        value={this.state.addr_line_1}/>
                                                    <TextField
                                                        className={"mb-2"}
                                                        fullWidth={true}
                                                        label="Address Line 2"
                                                        variant="outlined"
                                                        onChange={this.setAddrLine2}
                                                        value={this.state.addr_line_2}/>
                                                    <TextField
                                                        className={"mb-2"}
                                                        fullWidth={true}
                                                        label="City"
                                                        variant="outlined"
                                                        onChange={this.setCity}
                                                        value={this.state.city}/>
                                                    <TextField
                                                        className={"mb-2"}
                                                        fullWidth={true}
                                                        label="State"
                                                        variant="outlined"
                                                        onChange={this.setStateIn}
                                                        value={this.state.state}/>
                                                    <TextField
                                                        className={"mb-2"}
                                                        fullWidth={true}
                                                        label="Area Zipcode"
                                                        type="number"
                                                        variant="outlined"
                                                        onChange={this.setZipCode}
                                                        value={this.state.zipcode}/>
                                                    <div
                                                        className={"mb-2"}>
                                                        <CountrySelect
                                                            setCountry={this.setCountry}/>
                                                    </div>
                                                    <div
                                                        className={"mb-2"}>
                                                        <AddressTypeSelectTextField
                                                            setAddressType={this.setAddressType}
                                                            address_type={this.state.address_type}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                hidden={!(this.state.showAddressForm)}
                                                className="row text-center">
                                                <div className="col-12 my-1">
                                                    <Button
                                                        variant={"contained"}
                                                        color={"primary"}
                                                        startIcon={<Save/>}
                                                        onClick={() => this.saveAddressBtnClicked()}>
                                                        Save Address
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-details">
                                                <div className="order-box">
                                                    <div className="title-box">
                                                        <div>Product <span> Total</span></div>
                                                    </div>
                                                    <ul className="qty">
                                                        {cartItems.map((item, index) => {
                                                            return <li key={index}>{item.name} × {item.qty}
                                                                <span>{symbol} {item.sum}</span></li>
                                                        })
                                                        }
                                                    </ul>
                                                    <ul className="sub-total">
                                                        <li>Subtotal <span className="count">{symbol}{total}</span></li>
                                                        <li>Shipping Charges
                                                            <span className="count">
                                                                {
                                                                    (this.state.shipping_charge !== 0) ?
                                                                        symbol + this.state.shipping_charge.toFixed(2)
                                                                        :
                                                                        null
                                                                }
                                                            </span>
                                                        </li>

                                                        {/*<li>Shipping Charges
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <label>
                                                                        {
                                                                            total >= 1000 ?
                                                                                "(Free Shipping Available)"
                                                                                :
                                                                                "(Free Shipping Not Available)"
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col">
                                                                    <label>
                                                                        {
                                                                            this.state.shipping_charge !== 0 ?
                                                                                "₹ "+this.state.shipping_charge.toFixed(2)
                                                                                :
                                                                                null
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </li>*/}
                                                    </ul>

                                                    <ul className="total">
                                                        <li>Total <span
                                                            className="count">{symbol}{(total + this.state.shipping_charge).toFixed(2)}</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="payment-box">
                                                    <div hidden={true} className="upper-box">
                                                        <div className="payment-options">
                                                            <ul>
                                                                <li>
                                                                    <div className="radio-option stripe">
                                                                        <input type="radio" name="payment-group"
                                                                               id="payment-2" defaultChecked={true}
                                                                               onClick={() => this.checkhandle('stripe')}/>
                                                                        <label htmlFor="payment-2">Stripe</label>
                                                                    </div>
                                                                </li>
                                                                {/*<li>
                                                                    <div className="radio-option paypal">
                                                                        <input type="radio" name="payment-group" id="payment-1"
                                                                               onClick={() => this.checkhandle('paypal')}/>
                                                                        <label htmlFor="payment-1">PayPal<span
                                                                            className="image"><img
                                                                            src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`}
                                                                            alt=""/></span></label>
                                                                    </div>
                                                                </li>*/}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {(total !== 0) ?
                                                        <div className="text-right">
                                                            <button id="placeOrdBtn" type="button"
                                                                    className="btn-solid btn"
                                                                    onClick={handlePlaceOrderClick}>
                                                                {this.state.invoice_id === '' ? "Place Order" : "Pay Now"}
                                                            </button>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    userInfo: state.userInfo,
    cart: state.cartList.cart
})

export default connect(
    mapStateToProps,
    {
        clearCart,
        removeFromWishlist,
        receiveUserProfile,
        registerUser,
        fetchUserToken,
        receiveUserAddresses,
        selectUserAddresses,
        deselectUserAddresses,
        logoutUser
    }
)(checkOut)
