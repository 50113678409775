import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux'
import {Product4, Product5} from '../../../services/script'
import {addToCart, addToWishlist, addToCompare} from "../../../actions/index";
import ProductItem from '../../features/product/common/product-style-five';
import {fetchTrendingProducts} from "../../../actions";
import PostLoader from "../../common/PostLoader";

class TrendingCollection extends Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.props.fetchTrendingProducts()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.items !== prevProps.items && this.props.items.length > 0) {
            setTimeout(() => {
                this.setState({
                    loading: false,
                })
            }, 1000)
        }
    }

    render() {

        const {items, symbol, addToCart, addToWishlist, addToCompare, type} = this.props;
        const {loading} = this.state;

        var properties;
        if (type === 'kids') {
            properties = Product5
        } else {
            properties = Product4
        }

        return (
            <div>
                {/*Paragraph*/}
                <div className="title1  section-t-space">
                    <h4>special offer</h4>
                    <h2 className="title-inner1">trending products</h2>
                </div>
                {/*Paragraph End*/}
                <section className="section-b-space p-t-0">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                {
                                    items && loading === false ?
                                        <Slider {...properties} className="product-4 product-m no-arrow">
                                            {
                                                items.map((product, index) =>
                                                    <div key={index}>
                                                        <ProductItem product={product} symbol={symbol}
                                                                     onAddToCompareClicked={() => addToCompare(product)}
                                                                     onAddToWishlistClicked={() => addToWishlist(product)}
                                                                     onAddToCartClicked={() => addToCart(product, 1)}
                                                                     key={index}/>
                                                    </div>)
                                            }
                                        </Slider>
                                        :
                                        <div className="row mx-0 margin-default">
                                            <div className="col-xl-3 col-lg-4 col-6">
                                                <PostLoader />
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-6">
                                                <PostLoader />
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-6">
                                                <PostLoader />
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-6">
                                                <PostLoader />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.data.trending_products,
    symbol: state.data.symbol
})

export default connect(mapStateToProps, {
    fetchTrendingProducts,
    addToCart,
    addToWishlist,
    addToCompare
})(TrendingCollection);