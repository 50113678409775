import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {addToCart, addToCompare, addToWishlist, clearProducts, getAllProducts} from '../../../actions'
import {getVisibleProducts} from '../../../services';
import ProductListItem from "./product-list-item";
import {ProductListLoader} from "../../common/ProductListLoader";

class ProductListing extends Component {

    constructor(props) {
        super(props)

        this.state = {limit: 0, hasMoreItems: true, loading: true};
        setTimeout(() => {
            this.props.getVisibleProducts([], 3, this.props.filters, cb => {
                if (cb.length !== 0) {
                    this.setState({
                        limit: 3,
                        loading: false
                    })
                }
            });
        }, 1000)
    }

    fetchMoreItems = () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            this.props.getVisibleProducts(this.props.products, 3, this.props.filters, cb => {
                if (cb.length !== 0) {
                    this.setState({
                        limit: this.state.limit + cb.length,
                        loading: false,
                    });
                } else {
                    this.setState({
                        hasMoreItems: false,
                        loading: false,
                    });
                }
            });
        }, 1000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filters !== this.props.filters)
            this.setState({
                limit: this.props.products.length,
                hasMoreItems: true,
            });
    }

    render() {
        const {products, addToCart, symbol, addToWishlist, addToCompare} = this.props;
        const {loading, limit} = this.state;

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {
                            loading && products.length < 0 &&
                            <ProductListLoader/>
                        }
                        {products.length > 0 ?
                            <React.Fragment>
                                <div id="infinite-scroll-component" className="row">
                                    {limit > 0 && products.length !== 0 && products.map((pr, index) =>
                                        <div
                                            className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`}
                                            key={index}>
                                            <ProductListItem product={pr} symbol={symbol}
                                                             onAddToCompareClicked={() => addToCompare(pr)}
                                                             onAddToWishlistClicked={(event) => {
                                                                 event.preventDefault()
                                                                 addToWishlist(pr)
                                                             }}
                                                             onAddToCartClicked={addToCart}
                                                             key={index}/>
                                        </div>)
                                    }
                                    {
                                        loading && products.length > 0 &&
                                        <ProductListLoader/>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            loading &&
                                            <div className="loading-cls"/>
                                        }
                                    </div>
                                    <button
                                        disabled={loading}
                                        className="btn btn-solid my-4"
                                        style={{margin: "auto"}}
                                        onClick={this.fetchMoreItems}
                                    >
                                        Load More
                                    </button>
                                </div>
                            </React.Fragment>
                            /*<InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={() => setTimeout(() => {
                                    this.fetchMoreItems()
                                }, 1000)}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"/>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                scrollThreshold={0.7}
                            >
                            </InfiniteScroll>*/
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img
                                        loading={"lazy"}
                                        src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                        className="img-fluid mb-4" alt={""}/>
                                    <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                    <p>Please check if you have misspell something or try searching with other
                                        words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                        shopping</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.data.products,
    symbol: state.data.symbol,
    filters: state.filters
})

export default connect(
    mapStateToProps, {getVisibleProducts, getAllProducts, addToCart, addToWishlist, addToCompare, clearProducts}
)(ProductListing)