import React from 'react';

function Rating(props) {
    const {rating} = props
    const ratingStyle = {
        color: "#FFA200",
        marginTop: 15
    }

    return (
        <div style={ratingStyle}>
            <i className={
                rating >= 1 ?
                    'fa fa-star'
                    :
                    rating >= 0.5 ?
                        'fa fa-star-half-o'
                        :
                        'fa fa-star-o'
            }
            />
            <i className={
                rating >= 2 ?
                    'fa fa-star'
                    :
                    rating >= 1.5 ?
                        'fa fa-star-half-o'
                        :
                        'fa fa-star-o'
            }
            />
            <i className={
                rating >= 3 ?
                    'fa fa-star'
                    :
                    rating >= 2.5 ?
                        'fa fa-star-half-o'
                        :
                        'fa fa-star-o'
            }
            />
            <i className={
                rating >= 4 ?
                    'fa fa-star'
                    :
                    rating >= 3.5 ?
                        'fa fa-star-half-o'
                        :
                        'fa fa-star-o'
            }
            />
            <i className={
                rating >= 5 ?
                    'fa fa-star'
                    :
                    rating >= 4.5 ?
                        'fa fa-star-half-o'
                        :
                        'fa fa-star-o'
            }
            />
        </div>
    );
}

export default Rating;