import React, {useEffect} from 'react';
import {Button, Grid, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {MoreVert} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        flexDirection: "row",
        alignItems: "center"
    },
    paperItem: {},
    paperMore: {},
    payBtn: {},
    typoProductDetail: {
        color: '#222222'
    }
}))

function OrderGridItem(props) {
    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let [total, setTotal] = React.useState(0);

    useEffect(() => {
        let total_t = 0
        props.data.items.forEach((item) => {
            total_t = total_t + total
            total_t = total_t + ((item.amount * item.quantity) / 100)
            setTotal(total_t)
        })
    }, [])

    const getOrderStatusText = (status) => {
        switch (status) {
            case 'unpaid':
                return 'Payment Pending'
            case 'paid':
                return 'Order Confirmed'
            case 'shipping_rn':
                return 'Ready To Ship'
            case 'shipping':
                return 'Shipping'
            case 'in_transit':
                return 'In Transit'
            case 'out_delivery':
                return 'Out For Delivery'
            case 'delivered':
                return 'Delivered'
            default:
                return 'Unknown Status'
        }
    }

    return (
        <React.Fragment>
            <Grid
                // xs={fullScreen ? 12 : 6}
                item>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        spacing={2}
                        direction={"row"}>
                        <Grid
                            xs={fullScreen ? 12 : 8}
                            item
                            className={classes.paperItem}>
                            {
                                props.data.items.map((item, idx) =>
                                    <Grid
                                        key={idx}
                                        container
                                        direction={"row"}
                                        spacing={2}
                                        item>
                                        <Grid
                                            xs
                                            item>
                                            <img
                                                alt={item.name}
                                                width={100}
                                                height={67}
                                                src={
                                                    (item.product && item.product.pictures[0] + "?q=100&w=100") ||
                                                    (item.name === 'Shipping Charge' && `${process.env.PUBLIC_URL}/assets/images/shipping-delivery.svg`)
                                                }
                                                loading={"lazy"}
                                            />
                                        </Grid>
                                        <Grid
                                            xs
                                            item>
                                            {
                                                fullScreen ?
                                                    <React.Fragment>
                                                        <Typography
                                                            component={"div"}
                                                            className={classes.typoProductDetail}
                                                            style={{fontWeight: 600}}
                                                            variant={"caption"}>
                                                            {item.name}
                                                        </Typography>
                                                        <Typography
                                                            component={"div"}
                                                            className={classes.typoProductDetail}
                                                            // style={{color: '#f0b558'}}
                                                            variant={"caption"}>
                                                            {item.amount /100} x  {item.quantity}: {item.currency === "INR" ? "₹" : item.currency}{item.currency === "INR" ? ((item.amount * item.quantity) / 100) : (item.amount * item.quantity)}
                                                        </Typography>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Typography
                                                            component={"div"}
                                                            className={classes.typoProductDetail}
                                                            // style={{color: '#f0b558'}}
                                                            variant={"caption"}>
                                                            {item.name}
                                                        </Typography>
                                                        <Typography
                                                            component={"div"}
                                                            // className={classes.typoProductDetail}
                                                            // style={{color: '#f0b558'}}
                                                            variant={"caption"}>
                                                            Quantity: {item.quantity}
                                                        </Typography>
                                                        <Typography
                                                            component={"div"}
                                                            // className={classes.typoProductDetail}
                                                            // style={{color: '#f0b558'}}
                                                            variant={"caption"}>
                                                            Price: {item.currency === "INR" ? "₹" : item.currency}{item.amount / 100}/unit
                                                        </Typography>
                                                    </React.Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid
                            item>
                            <Typography
                                className={classes.typoProductDetail}
                                component={"div"}
                                // style={{fontWeight: 600}}
                                variant={"body1"}>
                                Total: ₹{total}
                            </Typography>
                            <Typography
                                className={classes.typoProductDetail}
                                style={{color: "green"}}
                                component={"div"}
                                // style={{fontWeight: 600}}
                                variant={"caption"}>
                                Ordered on {new Date(props.data.order_date).toDateString()}
                            </Typography>
                            <Typography
                                style={{color: props.data.status === 'unpaid' ? 'red' : 'green'}}
                                variant={"caption"}>
                                {
                                    getOrderStatusText(props.data.status)
                                }
                            </Typography>
                            <Typography
                                className={classes.typoProductDetail}
                                component={"div"}
                                variant={"caption"}>
                                Invoice ID: {props.data.invoice_id}
                            </Typography>
                        </Grid>
                        <Grid
                            component={"div"}
                            hidden={!(props.data.status === "unpaid")}
                            xs={12}
                            item
                            className={classes.payBtn}>
                            {
                                props.data.status === "unpaid" ?
                                    <Button
                                        onClick={() => props.payBtnClick(props.data.invoice_id)}
                                        variant={"outlined"}
                                        style={{float: "right", color: 'red'}}>
                                        Pay Now
                                    </Button>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>

    );
}

export default OrderGridItem;